import LineChart from '@cloudscape-design/components/line-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Average credit card interest rates',
    type: 'line',
    data: [
      { x: new Date(2016, 0), y: 14.52 },
      { x: new Date(2017, 0), y: 15.35 },
      { x: new Date(2018, 0), y: 16.46 },
      { x: new Date(2019, 0), y: 17.14 },
      { x: new Date(2020, 0), y: 16.03 },
      { x: new Date(2021, 0), y: 16.26 },
    ],
    valueFormatter: e => `${e}%`,
  },
];

const CreditCardInterestGraph = () => {
  return (
    <>
      <LineChart
        series={CHART_DATA}
        xDomain={[new Date(2016, 0), new Date(2021, 1)]}
        yDomain={[13, 18]}
        i18nStrings={{
          xTickFormatter: e =>
            e.toLocaleDateString('en-US', {
              year: 'numeric',
            }),
          yTickFormatter: function o(e) {
            return `${e.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`;
          },
        }}
        height={300}
        hideFilter
        xScaleType="time"
        xTitle="Year"
        yTitle="Average credit card interest rate"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Average credit card interest rates from 2016 to 2021, source:{' '}
          <a
            className="font-bold"
            href="https://www.creditcards.com"
            target="_blank"
            rel="noreferrer"
          >
            creditcards.com
          </a>
        </Typography>
      </figcaption>
    </>
  );
};

export default CreditCardInterestGraph;

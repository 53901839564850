import BarChart from '@cloudscape-design/components/bar-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Average credit card balance',
    type: 'bar',
    data: [
      { x: '2019', y: 1183 },
      { x: '2020', y: 1402 },
      { x: '2021', y: 1579 },
    ],
    valueFormatter: e =>
      `$${e.toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })}`,
  },
];

const CreditCardBalanceGraph = () => {
  return (
    <>
      <BarChart
        series={CHART_DATA}
        xDomain={['2019', '2020', '2021']}
        yDomain={[0, 1800]}
        i18nStrings={{
          yTickFormatter: e =>
            `$${e.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}`,
        }}
        hideFilter
        height={400}
        xTitle="Year"
        yTitle="Average credit card balance"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Average credit card balances for college students, source:{' '}
          <a
            className="font-bold"
            href="https://www.experian.com"
            target="_blank"
            rel="noreferrer"
          >
            Experian
          </a>
        </Typography>
      </figcaption>
    </>
  );
};

export default CreditCardBalanceGraph;

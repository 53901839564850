import * as d3 from 'd3';

const addAxis = (axisPosition, graph, scale, label, props) => {
  /*
  example props
  {
    graph: {
      height: 700,
      width: 600,
      margin: { top: 10, right: 0, bottom: 35, left: 68 },
    },
    ticks: {
      dataGap: 50, (optional)
      tickSize: 6, (optional)
      translateX: 15, (default to 0)
      translateY: 15, (default to 0)
    },
    label: {
      defaultFormat: true (optional)
      textLength: 30, (optional)
      translateX: 15, (default to middle of the axis)
      translateY: 15, (default to middle of the axis)
    },
    domain: {
      translateX: 10, (default to 0)
      translateY: 10, (default to 0)
    }
    translateX: 10, (default to 0)
    translateY: 10, (default to 0)
  }
  */

  const axisType =
    axisPosition === 'bottom' || axisPosition === 'top' ? 'xAxis' : 'yAxis';
  graph.append('g').classed(axisType, true);

  let axis;
  switch (axisPosition) {
    case 'bottom':
      axis = d3.axisBottom();
      break;
    case 'top':
      axis = d3.axisTop();
      break;
    case 'left':
      axis = d3.axisLeft();
      break;
    case 'right':
      axis = d3.axisRight();
      break;
    default:
      throw new Error(
        'axisPosition argument should be either bottom, top, left or right'
      );
  }

  if (!axis)
    throw new Error(
      'axisPosition argument should be either bottom, top, left or right'
    );

  axis.scale(scale);
  if (props.ticks?.dataGap) {
    const scaleDomain = scale.domain();
    const numberOfTicks =
      Math.ceil(
        (scaleDomain[scaleDomain.length - 1] - scaleDomain[0]) /
          props.ticks.dataGap
      ) + 1;
    axis.ticks(numberOfTicks);
  }
  if (props.ticks?.tickSize !== undefined) {
    axis.tickSize(props.ticks.tickSize);
  }

  if (!props.label?.defaultFormat) {
    axis.tickFormat(d => d);
  }

  graph
    .select(`.${axisType}`)
    .call(axis)
    .attr(
      'transform',
      `translate(${
        axisType === 'yAxis'
          ? props.graph.margin.left + (props.translateX || 0)
          : 0
      },${
        axisType === 'xAxis'
          ? props.graph.height -
            props.graph.margin.bottom +
            (props.translateY || 0)
          : 0
      })`
    )
    .call(g =>
      g
        .select('.domain')
        .attr(
          'transform',
          `translate(${props.domain?.translateX || 0},${
            props.domain?.translateY || 0
          })`
        )
    )
    .call(g => {
      g.selectAll('.tick text')
        .attr('font-size', '14')
        .attr('dx', props.ticks?.translateX || 0)
        .attr('dy', props.ticks?.translateY || 0);
    })
    .call(g => {
      const axisLabel = g
        .append('text')
        .text(label)
        .attr('font-size', '14px')
        .attr('lengthAdjust', 'spacingAndGlyphs')
        .attr('fill', 'currentColor')
        .attr(
          'x',
          props.label?.translateX || axisType === 'xAxis'
            ? props.graph.width / 2 +
                (props.label?.textLength ? props.label?.textLength / 2 : 0)
            : (props.graph.margin.bottom - props.graph.height) / 2 +
                (props.label?.textLength ? props.label?.textLength / 2 : 0)
        )
        .attr(
          'y',
          props.label?.translateY ||
            (props.graph.margin.bottom - props.graph.height) / 2 + // -(props.graph.height - props.graph.margin.bottom)
              (props.label?.textLength ? props.label?.textLength / 2 : 0)
        );
      if (axisType === 'yAxis') {
        axisLabel.attr('transform', 'rotate(270)');
      }
      if (props.label?.textLength) {
        axisLabel.attr('textLength', props.label.textLength);
      }
      return axisLabel;
    });
};

export { addAxis };

import { useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';

import { ChevronDownIcon } from '@heroicons/react/24/solid';

const CategoryDropdown = ({ currentItem, items, clickHandler }) => {
  const [ref, isVisible, setIsVisible] = useOutsideClick();
  const [initDropdownVis, setInitDropdownVis] = useState('invisible');

  // quickfix for animation during inital render
  const handleDropdownClick = () => {
    setInitDropdownVis('');
    setIsVisible(!isVisible);
  };

  return (
    <div ref={ref} className="relative inline-block text-left mb-5">
      <div>
        <button
          onClick={handleDropdownClick}
          type="button"
          className="inline-flex justify-center w-full rounded-full border border-primary-500 shadow-sm px-4 py-2.5 bg-white text-xl font-medium text-primary-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          aria-haspopup="true"
        >
          {currentItem.name}
          <ChevronDownIcon
            className="ml-2 h-6 w-6 self-center"
            aria-hidden="true"
          />
        </button>
      </div>

      <div
        className={`origin-top-left absolute w-[200px] sm:w-[500px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 mt-2 focus:outline-none py-2 ${initDropdownVis} ${
          isVisible ? 'fade-in' : 'fade-out'
        }`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-0">
          {items.map(item => (
            <button
              key={item.id}
              onClick={() => {
                clickHandler(item);
                setIsVisible(false);
              }}
              className="block text-gray-700 text-base text-left px-4 py-2 hover:bg-gray-50 hover:text-primary-600"
              role="menuitem"
              tabIndex="-1"
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDropdown;

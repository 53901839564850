import graph11_1 from './graph11/graph1';
import graph11_2 from './graph11/graph2';
import graph11_3 from './graph11/graph3';
import graph12_1 from './graph12/graph1';
import graph12_2 from './graph12/graph2';
import graph12_3 from './graph12/graph3';
import graph12_4 from './graph12/graph4';

const plotGraph = slug => {
  if (slug === 'investing-for-college-students') {
    graph11_1();
    graph11_2();
    graph11_3();
  } else if (slug === 'best-credit-cards-college-students') {
    graph12_1();
    graph12_2();
    graph12_3();
    graph12_4();
  }
};

export default plotGraph;

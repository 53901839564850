import * as d3 from 'd3';
import { addAxis } from '../utils/axes';
import { addToolTip } from '../utils/tooltip';

const graph1 = () => {
  const aspectRatio = 1.5;
  const width = 710;
  const height = width / aspectRatio;
  const margin = { top: 10, right: 0, bottom: 35, left: 70 };

  const investmentValues = [
    50000, 61320, 73773, 87470, 102537, 119111, 137343, 157397, 179457, 203723,
    230416,
  ];
  const highestAxisInvestmentValues = 250000;
  const years = Array.from({ length: 11 }, (_, i) => i);

  const xScale = d3
    .scaleBand()
    .domain(years)
    .range([margin.left, width - margin.right])
    .padding(0.4);
  const yScale = d3
    .scaleLinear()
    .domain([0, highestAxisInvestmentValues])
    .range([height - margin.bottom, margin.top]);

  const data = investmentValues.map((d, i) => {
    return {
      investmentValue: d,
      year: years[i],
      x: xScale(years[i]),
      y: yScale(d),
      height: height - margin.bottom - yScale(d),
    };
  });

  const graphContainer = d3
    .select('#graph1')
    .text('')
    .style('overflow', 'auto');
  const graph = graphContainer
    .append('svg')
    .attr('height', height)
    .attr('width', width);
  graphContainer
    .append('figcaption')
    .classed('text-center text-sm', true)
    .html(
      'Figure 1: Investment returns over 10 years with compounding interest'
    );

  // bar chart
  graph
    .selectAll('rect')
    .data(data)
    .join(enter => {
      const rect = enter
        .append('rect')
        .attr('x', d => d.x)
        .attr('y', _ => height - margin.bottom)
        .attr('height', _ => 0)
        .attr('width', xScale.bandwidth())
        .attr('fill', '#186FAF');
      return rect;
    })
    .transition()
    .attr('y', d => d.y)
    .attr('height', d => d.height);

  // data label
  graph
    .selectAll('text')
    .data(data)
    .join('text')
    .text(d => `$${d.investmentValue.toLocaleString()}`)
    .attr('x', d => d.x - Math.floor((60 - xScale.bandwidth()) / 2))
    .attr('y', d => d.y - 8)
    .attr('font-size', '14px')
    .attr('textLength', 60)
    .attr('lengthAdjust', 'spacingAndGlyphs');

  addToolTip(
    graphContainer,
    graph,
    'rect',
    Math.ceil(years.length / 2),
    (tooltip, d) => {
      tooltip.html(
        `<strong>Year:</strong> ${
          d.year
        }<br /><strong>Investment Value:</strong> $${d.investmentValue.toLocaleString()}`
      );
    }
  );

  addAxis('left', graph, yScale, 'Investment Value ($)', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 50000,
    },
    label: {
      textLength: 130,
      translateY: -67,
    },
    translateX: 8,
  });

  addAxis('bottom', graph, xScale, 'Years', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 1,
      tickSize: 0,
      translateY: 12,
    },
    label: {
      textLength: 36,
      translateY: 28,
    },
    domain: {
      translateX: 8,
    },
  });
};

export default graph1;

import { Link } from 'react-router-dom';

const ToolListPage = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-20">
      <header className="mb-10">
        <h1 className="text-5xl font-semibold mb-6">Tools</h1>
        <div className="text-xl text-slate-700 leading-7">
          <p className="mb-4">
            Investing is no easy feat, especially when it involves various
            valuation methods and calculations. But worry not! We have got your
            back.
          </p>
          <p className="mb-4">
            Alpha Investing Group has prepared various investment tools that
            will speed up your investment calculations and valuation process.
            Furthermore, we also have tools that cover topics such as personal
            finance, financial literacy, cryptocurrency, and many more. Explore
            our tools now and let them help you in your journey of investing.
          </p>
          <p className="mb-4">
            Please feel free to leave us a comment or send an email to{' '}
            <a
              href="mailto:alphainvestinggroup.general@gmail.com"
              className="text-primary-600 hover:text-primary-700 hover:underline"
            >
              alphainvestinggroup.general@gmail.com
            </a>
            {' if '}
            there are some specific tools that you would like us to build.
          </p>
        </div>
      </header>

      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
        <li className="col-span-1 rounded-lg shadow-xl flex justify-center h-[5.8rem] hover:scale-105">
          <Link
            to="financial-literacy-test"
            className="whitespace-normal text-primary-600 text-lg font-medium h-full w-full flex justify-center px-10"
          >
            <span className="self-center text-center">
              Financial Literacy Test
            </span>
          </Link>
        </li>
        <li className="col-span-1 rounded-lg shadow-xl flex justify-center h-[5.8rem] hover:scale-105">
          <Link
            to="cryptocurrency-converter"
            className="whitespace-normal text-primary-600 text-lg font-medium h-full w-full flex justify-center px-10"
          >
            <span className="self-center text-center">
              Cryptocurrency Converter
            </span>
          </Link>
        </li>
        <li className="col-span-1 rounded-lg shadow-xl flex justify-center h-[5.8rem] hover:scale-105">
          <Link
            to="index-investing-calculator"
            className="whitespace-normal text-primary-600 text-lg font-medium h-full w-full flex justify-center px-10"
          >
            <span className="self-center text-center">
              Index Investing Calculator
            </span>
          </Link>
        </li>
        <li className="col-span-1 rounded-lg shadow-xl flex justify-center h-[5.8rem] hover:scale-105">
          <Link
            to="mortgage-calculator"
            className="whitespace-normal text-primary-600 text-lg font-medium h-full w-full flex justify-center px-10"
          >
            <span className="self-center text-center">Mortgage Calculator</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ToolListPage;

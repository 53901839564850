import LineChart from '@cloudscape-design/components/line-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Percentage of credit cardholders participating in rewards programs',
    type: 'line',
    data: [
      { x: new Date(2016, 0), y: 64 },
      { x: new Date(2017, 0), y: 68 },
      { x: new Date(2018, 0), y: 69 },
      { x: new Date(2019, 0), y: 58 },
      { x: new Date(2020, 0), y: 71 },
      { x: new Date(2021, 0), y: 75 },
    ],
    valueFormatter: e => `${e}%`,
  },
];

const RewardParticipationPercentageGraph = () => {
  return (
    <>
      <LineChart
        series={CHART_DATA}
        xDomain={[new Date(2016, 0), new Date(2021, 1)]}
        yDomain={[50, 80]}
        i18nStrings={{
          xTickFormatter: e =>
            e.toLocaleDateString('en-US', {
              year: 'numeric',
            }),
          yTickFormatter: function o(e) {
            return `${e}%`;
          },
        }}
        height={300}
        hideFilter
        xScaleType="time"
        xTitle="Year"
        yTitle="Percentage of credit cardholders participating in rewards programs"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Percentage of credit cardholders participating in rewards programs,
          source:{' '}
          <a
            className="font-bold"
            href="http://comparecards.com"
            target="_blank"
            rel="noreferrer"
          >
            comparecards.com
          </a>
        </Typography>
      </figcaption>
    </>
  );
};

export default RewardParticipationPercentageGraph;

import { useState, useEffect, useRef } from 'react';
import CurrencyItem from './CurrencyItem';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { queryCoins } from './utils';

const Currency = ({
  currencyType,
  top10Coins,
  currency: [currency, setCurrency],
  handleCoinUpdate,
  handleAmountUpdate,
  setError,
}) => {
  const searchBoxRef = useRef(null);
  const [searchResultRef, searchActive, setSearchActive] = useOutsideClick();
  const [query, setQuery] = useState('');
  const [queryResult, setQueryResult] = useState(top10Coins);

  useEffect(() => {
    async function searchCoin() {
      if (!query.trim()) {
        setQueryResult(top10Coins);
      } else {
        let queryResponse;
        try {
          queryResponse = await queryCoins(query);
        } catch (e) {
          setError(e);
        }
        setQueryResult(queryResponse || []);
      }
    }

    const searchCoinTimeout = setTimeout(() => {
      searchCoin();
    }, 500);

    return () => clearTimeout(searchCoinTimeout);
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchActive) {
      searchBoxRef.current.focus();
      searchBoxRef.current.select();
    }
  }, [searchActive]);

  return (
    <section className="flex-auto">
      <div className="grid grid-cols-2 bg-white rounded-md w-full h-16 mt-2">
        <input
          className="block text-xl text-slate-700 border-r-2 rounded-l-md h-full px-4"
          value={currency.amount ? currency.amount : ''}
          onChange={e => {
            setCurrency(c => ({ ...c, amount: e.target.value }));
            handleAmountUpdate(e.target.value, currencyType);
          }}
        ></input>

        <div ref={searchResultRef} className="relative w-full">
          <input
            ref={searchBoxRef}
            className={`text-xl text-slate-700 rounded-r-md h-full w-full px-4 ${
              searchActive ? 'block' : 'hidden'
            }`}
            placeholder="Search"
            value={query}
            onChange={e => setQuery(e.target.value)}
          ></input>
          <CurrencyItem
            className={searchActive ? 'hidden' : ''}
            img={currency.image}
            name={currency.name}
            id={currency.id}
            onClick={() => {
              setSearchActive(true);
            }}
            isQueryResult={false}
          />
          <ul
            className={`absolute bg-white shadow-2xl max-h-64 w-full overflow-auto rounded-md mt-2 ${
              searchActive ? 'block' : 'hidden'
            }`}
          >
            {queryResult.map(({ id, name, image }) => (
              <li
                key={id}
                className="py-1 hover:bg-gray-100 hover:cursor-pointer"
              >
                <CurrencyItem
                  img={image}
                  name={name}
                  id={id}
                  onClick={() => {
                    setSearchActive(false);
                    setCurrency(c => ({
                      ...c,
                      id: id,
                      name: name,
                      image: image,
                    }));
                    handleCoinUpdate(id, currencyType);
                  }}
                  isQueryResult={true}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Currency;

import PieChart from '@cloudscape-design/components/pie-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  { title: 'Payment history', value: 35 },
  { title: 'Credit utilisation ratio', value: 30 },
  { title: 'Length of credit history', value: 15 },
  { title: 'Types of credit in use', value: 10 },
  { title: 'New credit', value: 10 },
];

const CreditScoreInfluencingFactorsGraph = () => {
  return (
    <>
      <PieChart
        variant="donut"
        data={CHART_DATA}
        innerMetricValue="100%"
        size="large"
        hideFilter
        fitHeight
        segmentDescription={datum => `${datum.value}%`}
        detailPopoverContent={datum => [
          {
            key: 'Percentage',
            value: `${datum.value}%`,
          },
        ]}
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Factors that influence credit score
        </Typography>
      </figcaption>
    </>
  );
};

export default CreditScoreInfluencingFactorsGraph;

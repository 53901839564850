const format = amount => {
  let precision;
  if (amount < 0.000_001) {
    precision = 12;
  } else if (amount < 0.05) {
    precision = 8;
  } else if (amount < 1) {
    precision = 6;
  } else if (amount < 1.1) {
    precision = 3;
  } else {
    precision = 2;
  }
  return (
    Math.round((amount + Number.EPSILON) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  );
};

const convert = (baseAmount, baseUSDValue, targetUSDValue) => {
  const result = (baseAmount * baseUSDValue) / targetUSDValue;
  return format(result);
};

export default convert;

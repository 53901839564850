import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';

import HomePage from './pages/Home/HomePage';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import BlogListPage from './pages/Blogs/BlogListPage';
import BlogDetailsPage from './pages/Blogs/BlogDetailsPage';
import ToolListPage from './pages/Tools/ToolListPage';
import IndexInvestingCalculator from './pages/Tools/IndexInvestingCalculator';
import MortgageCalculator from './pages/Tools/MortgageCalculator';
import CryptoConverter from './pages/Tools/CryptoConverter/CryptoConverter';
import FinancialLiteracyTestPage from './pages/Tools/FinancialLiteracyTest/FinancialLiteracyTestPage';
import AboutUs from './pages/AboutUs';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Helmet>
            <title>
              Best investment strategy and insights | Alpha Investing Group
            </title>
            <meta
              name="description"
              content="Alpha Investing Group aims to help investors to analyse and form independent investment decisions by providing the best investment tools and knowledge."
            />
            <script
              id="MathJax-script"
              defer
              src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
            ></script>
          </Helmet>
          <div className="flex flex-col h-full">
            <NavBar />
            <div className="grow">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="blogs" element={<BlogListPage />} />
                <Route path="blogs/:slug" element={<BlogDetailsPage />} />
                <Route path="tools" element={<ToolListPage />} />
                <Route
                  path="tools/index-investing-calculator"
                  element={<IndexInvestingCalculator />}
                />
                <Route
                  path="tools/mortgage-calculator"
                  element={<MortgageCalculator />}
                />
                <Route
                  path="tools/cryptocurrency-converter"
                  element={<CryptoConverter />}
                />
                <Route
                  path="tools/financial-literacy-test"
                  element={<FinancialLiteracyTestPage />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <div className="shrink-0">
              <Footer />
            </div>
          </div>
        </LocalizationProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

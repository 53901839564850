const Header = () => {
  return (
    <>
      <header>
        <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
          Tools
        </span>
        <h1 className="text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 mt-2">
          Cryptocurrency converter
        </h1>
      </header>
      <div className="text-xl text-slate-700 leading-7 mt-8">
        <p>
          Ever since the rise of Bitcoin, new cryptocurrencies are being created
          everyday. The rising of cryptocurrencies has created various
          opportunities, but its rapid development has also made the field more
          complex. Particularly, the relative values between different
          cryptocurrencies has become increasingly unclear.
        </p>
        <p className="mt-3">
          How much ETH you can buy with 1 unit of BTC? How much is ADA worth in
          BNB? If you ever have any of these questions, you are at the right
          place. With our cryptocurrency converter, you can answer all these
          questions instantly.
        </p>
        <p className="mt-3">
          All you need to do is to select the cryptocurrency pair in your mind
          and the results will be right before your eyes.
        </p>
        <p className="mt-3">It is that simple - give it a try!</p>
      </div>
    </>
  );
};

export default Header;

const customCSSPropertiesMap: Record<string, string> = {
  breadcrumbsGap: '--awsui-breadcrumbs-gap-iof9y8',
  contentGapLeft: '--awsui-content-gap-left-iof9y8',
  contentGapRight: '--awsui-content-gap-right-iof9y8',
  contentHeight: '--awsui-content-height-iof9y8',
  containerFirstGap: '--awsui-container-first-gap-iof9y8',
  containerFirstOverlapExtension: '--awsui-container-first-overlap-extension-iof9y8',
  defaultMaxContentWidth: '--awsui-default-max-content-width-iof9y8',
  defaultMinContentWidth: '--awsui-default-min-content-width-iof9y8',
  drawerSize: '--awsui-drawer-size-iof9y8',
  footerHeight: '--awsui-footer-height-iof9y8',
  headerGap: '--awsui-header-gap-iof9y8',
  headerHeight: '--awsui-header-height-iof9y8',
  layoutWidth: '--awsui-layout-width-iof9y8',
  mainGap: '--awsui-main-gap-iof9y8',
  mainOffsetLeft: '--awsui-main-offset-left-iof9y8',
  mainTemplateRows: '--awsui-main-template-rows-iof9y8',
  maxContentWidth: '--awsui-max-content-width-iof9y8',
  minContentWidth: '--awsui-min-content-width-iof9y8',
  mobileBarHeight: '--awsui-mobile-bar-height-iof9y8',
  notificationsGap: '--awsui-notifications-gap-iof9y8',
  notificationsHeight: '--awsui-notifications-height-iof9y8',
  offsetTop: '--awsui-offset-top-iof9y8',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-iof9y8',
  overlapHeight: '--awsui-overlap-height-iof9y8',
  navigationWidth: '--awsui-navigation-width-iof9y8',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-iof9y8',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-iof9y8',
  splitPanelHeight: '--awsui-split-panel-height-iof9y8',
  splitPanelMinWidth: '--awsui-split-panel-min-width-iof9y8',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-iof9y8',
  toolsMaxWidth: '--awsui-tools-max-width-iof9y8',
  toolsWidth: '--awsui-tools-width-iof9y8',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-iof9y8',
  contentScrollMargin: '--awsui-content-scroll-margin-iof9y8',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-iof9y8',
  flashbarStackIndex: '--awsui-flashbar-stack-index-iof9y8',
  flashbarStickyBottomMargin: '--awsui-flashbar-sticky-bottom-margin-iof9y8',
  stackedNotificationsBottomMargin: '--awsui-stacked-notifications-bottom-margin-iof9y8',
  stackedNotificationsDefaultBottomMargin: '--awsui-stacked-notifications-default-bottom-margin-iof9y8',
  dropdownDefaultMaxWidth: '--awsui-dropdown-default-max-width-iof9y8',
  spinnerRotatorFrom: '--awsui-spinner-rotator-from-iof9y8',
  spinnerRotatorTo: '--awsui-spinner-rotator-to-iof9y8',
  spinnerLineLeftFrom: '--awsui-spinner-line-left-from-iof9y8',
  spinnerLineLeftTo: '--awsui-spinner-line-left-to-iof9y8',
  spinnerLineRightFrom: '--awsui-spinner-line-right-from-iof9y8',
  spinnerLineRightTo: '--awsui-spinner-line-right-to-iof9y8',
};
export default customCSSPropertiesMap;

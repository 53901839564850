import BarChart from '@cloudscape-design/components/bar-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Credit card ownerships',
    type: 'bar',
    data: [
      { x: '18-24', y: 46 },
      { x: '25-34', y: 67 },
      { x: '35-44', y: 69 },
      { x: '45-54', y: 74 },
      { x: '55-64', y: 77 },
      { x: '65 and older', y: 70 },
    ],
    valueFormatter: e => `${e}%`,
  },
];

const CreditCardOwnershipPercentageGraph = () => {
  return (
    <>
      <BarChart
        series={CHART_DATA}
        xDomain={['18-24', '25-34', '35-44', '45-54', '55-64', '65 and older']}
        yDomain={[0, 100]}
        i18nStrings={{
          yTickFormatter: e => `${e}%`,
        }}
        hideFilter
        height={400}
        xTitle="Age groups"
        yTitle="Credit card ownerships"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Credit card ownership rates among different age groups, source:{' '}
          <a
            className="font-bold"
            href="https://www.federalreserve.gov/econres/scfindex.htm"
            target="_blank"
            rel="noreferrer"
          >
            Federal Reserve Survey of Consumer Finances
          </a>
        </Typography>
      </figcaption>
    </>
  );
};

export default CreditCardOwnershipPercentageGraph;

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import InvestmentWorthChart from './InvestmentWorthChart';
import { useFormContext } from 'react-hook-form';

const Results = ({ results }) => {
  const { getValues } = useFormContext();

  if (!results) return null;

  return (
    <>
      <Box display="flex" my={6}>
        <Card
          sx={{
            margin: '16px',
            marginLeft: 0,
            borderRadius: '16px',
            padding: '24px 32px',
            boxShadow:
              '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
            flexGrow: 1,
          }}
        >
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="20px"
            color="#0F609B"
            mb={1}
          >
            Total Invested
          </Typography>
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="18px"
            color="#374151"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(results.totalInvested.toFixed(2))}
          </Typography>
        </Card>
        <Card
          sx={{
            margin: '16px',
            marginRight: '16px',
            borderRadius: '16px',
            padding: '24px 32px',
            boxShadow:
              '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
            flexGrow: 1,
          }}
        >
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="20px"
            color="#0F609B"
            mb={1}
          >
            Current value
          </Typography>
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="18px"
            color="#374151"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(results.currentValue.toFixed(2))}
          </Typography>
        </Card>
        <Card
          sx={{
            marginTop: '16px',
            marginLeft: '16px',
            marginBottom: '16px',
            borderRadius: '16px',
            padding: '24px 32px',
            boxShadow:
              '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="20px"
            color="#0F609B"
            mt={2}
            mb={1}
          >
            Gain
          </Typography>
          <Typography
            fontWeight={700}
            fontFamily="Space Grotesk, sans-serif"
            fontSize="18px"
            color="#374151"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(results.currentValue - results.totalInvested.toFixed(2))}
          </Typography>
        </Card>
      </Box>
      <Box my={2}>
        <InvestmentWorthChart
          indexPriceHistory={results.indexPriceHistory}
          investmentWorth={results.monthlyInvestmentWorth}
          startDate={getValues('startDate')}
          indexType={getValues('index')}
        />
      </Box>
    </>
  );
};

export default Results;

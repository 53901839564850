import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <article className="prose prose-slate prose-xl break-words mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
      <h1 className="text-center">About Us</h1>
      <img
        src="logo512.png"
        alt="Alpha Invest Logo"
        className="float-left mr-5"
        style={{ width: '200px', height: '200px' }}
      />
      <p>
        Alpha Investing Group is a website and community that seeks to encourage
        sensible investing amongst the investing community. Recently, the online
        investing community is filled with subpar investing websites and
        channels that focus on recommending stocks or other investments to buy
        by demonstrating superficial analysis. This has led to a lot of
        investors blindly investing in hyped stocks recommended by them without
        analysing the investment opportunities themselves.
      </p>
      <p>
        We believe this is detrimental to investors and may be one of the main
        reasons why retail investors have suffered way below average return in
        recent years (accosting to research by J.P. Morgan, retail investors
        only earn about 1.9% annual return). Hence, Alpha Investing Group aims
        to spread the investing awareness, enable investors to make informed
        investment decisions and provide a platform to discuss investment ideas
        and approach.
      </p>
      <p>
        Alpha Investing Group may earn a small commission for our endorsement,
        recommendation, testimonial, or link to any products or services from
        this website. <Link to="/privacy-policy">Learn more</Link>
      </p>

      <h1 className="text-center mt-10 mb-8">Contact Us</h1>
      <p>
        If you have any suggestions about how to improve this site, or if you’ve
        found accuracies in the data or simply want to get in touch, then please
        send an email to{' '}
        <a href="mailto:alphainvestinggroup.general@gmail.com">
          alphainvestinggroup.general@gmail.com
        </a>
        .
      </p>
    </article>
  );
};

export default AboutUs;

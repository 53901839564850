import PieChart from '@cloudscape-design/components/pie-chart';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';

const PaymentBreakdownChart = ({ data }) => {
  return (
    <PieChart
      data={data}
      detailPopoverContent={(datum, sum) => [
        { key: datum.title, value: `$${datum.value}` },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(0)}%`,
        },
      ]}
      segmentDescription={(datum, sum) =>
        `$${datum.value}, ${((datum.value / sum) * 100).toFixed(0)}%`
      }
      hideFilter
      ariaDescription="Pie chart showing how many resources are currently in which state."
      ariaLabel="Pie chart"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default PaymentBreakdownChart;

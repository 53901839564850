import * as d3 from 'd3';
import { addAxis } from '../utils/axes';
import { addToolTip } from '../utils/tooltip';

const graph3 = () => {
  const aspectRatio = 1.5;
  const width = 710;
  const height = width / aspectRatio;
  const margin = { top: 10, right: 0, bottom: 35, left: 55 };

  const balances = [1183, 1402, 1579];
  const balancesExtent = [0, 2000];
  const years = Array.from({ length: 3 }, (_, i) => 2019 + i);

  const xScale = d3
    .scaleBand()
    .domain(years)
    .range([margin.left, width - margin.right])
    .padding(0.4);
  const yScale = d3
    .scaleLinear()
    .domain(balancesExtent)
    .range([height - margin.bottom, margin.top]);

  const data = balances.map((d, i) => {
    return {
      balance: d,
      year: years[i],
      x: xScale(years[i]),
      y: yScale(d),
      height: height - margin.bottom - yScale(d),
    };
  });

  const graphContainer = d3
    .select('#graph3')
    .text('')
    .style('overflow', 'auto');
  const graph = graphContainer
    .append('svg')
    .attr('height', height)
    .attr('width', width);
  graphContainer
    .append('figcaption')
    .classed('text-center text-sm', true)
    .html(
      'Figure 3: Average credit card balances for college students, source: <a href="https://www.experian.com" target="_blank" rel="noreferrer noopener">Experian</a>'
    );

  // bar chart
  graph
    .selectAll('rect')
    .data(data)
    .join(enter => {
      const rect = enter
        .append('rect')
        .attr('x', d => d.x)
        .attr('y', _ => height - margin.bottom)
        .attr('height', _ => 0)
        .attr('width', xScale.bandwidth())
        .attr('fill', '#186FAF');
      return rect;
    })
    .transition()
    .attr('y', d => d.y)
    .attr('height', d => d.height);

  // data label
  graph
    .selectAll('text')
    .data(data)
    .join('text')
    .text(d => `$${d.balance.toLocaleString()}`)
    .attr('x', d => d.x - Math.floor((45 - xScale.bandwidth()) / 2))
    .attr('y', d => d.y - 8)
    .attr('font-size', '14px')
    .attr('textLength', 45)
    .attr('lengthAdjust', 'spacingAndGlyphs');

  addToolTip(
    graphContainer,
    graph,
    'rect',
    Math.ceil(years.length / 2),
    (tooltip, d) => {
      tooltip.html(
        `<strong>Year:</strong> ${
          d.year
        }<br /><strong>Average Credit Card Balance:</strong> $${d.balance.toLocaleString()}`
      );
    },
    { width: 300 }
  );

  addAxis('left', graph, yScale, 'Average Credit Card Balance ($)', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 1000,
    },
    label: {
      textLength: 210,
      translateY: -50,
    },
    translateX: 8,
  });

  addAxis('bottom', graph, xScale, 'Year', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 1,
      tickSize: 0,
      translateY: 12,
    },
    label: {
      textLength: 30,
      translateY: 33,
    },
    domain: {
      translateX: 8,
    },
  });
};

export default graph3;

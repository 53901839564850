export const INDEX_TYPES = ['spx', 'nasdaq'];

const getIndexPriceHistory = async formValue => {
  const res = await fetch(
    `https://api.alphainvestinggroup.com/tools/index-investing?index=${
      formValue.index
    }&start_date=${formValue.startDate.toISOString()}&end_date=${formValue.endDate.toISOString()}`,
    {
      headers: { Authorization: process.env.REACT_APP_AIGAPIKEY },
    }
  );
  const json = await res.json();
  return json;
};

export const calculateResults = async formValue => {
  const indexPriceHistory = await getIndexPriceHistory(formValue);
  const monthlyInvestment = formValue.monthlyInvestment ?? 0;

  let totalInvested = formValue.investAmount;
  const monthlyInvestmentWorth = [formValue.investAmount];
  for (let i = 1; i < indexPriceHistory.values.length; i++) {
    const percentageIncrease =
      indexPriceHistory.values[i] / indexPriceHistory.values[i - 1];
    totalInvested += monthlyInvestment;
    monthlyInvestmentWorth.push(
      monthlyInvestmentWorth[i - 1] * percentageIncrease + monthlyInvestment
    );
  }

  return {
    indexPriceHistory: indexPriceHistory.values,
    monthlyInvestmentWorth,
    totalInvested,
    currentValue: monthlyInvestmentWorth[monthlyInvestmentWorth.length - 1],
  };
};

import { useState, useRef } from 'react';
import Header from './Header';
import Results from './Results';
import LoanAmountForm from './LoanAmountForm';
import OtherDetailsForm from './OtherDetailsForm';
import { calculateArmotization, calculateMonthlyPayment } from './helpers';

import Box from '@mui/material/Box';

const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [firstPaymentDate, setFirstPaymentDate] = useState(new Date());

  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [armotizationTable, setArmotizationTable] = useState([]);
  const [totalInterestPaid, setTotalInterestPaid] = useState(null);

  const resultSectionRef = useRef(null);

  const calculateResults = () => {
    const monthlyInterest = interestRate / 100 / 12;
    const monthlyPayment = calculateMonthlyPayment(
      loanAmount,
      loanTerm,
      monthlyInterest
    );
    const { armotization, totalInterestPaid } = calculateArmotization(
      loanAmount,
      monthlyPayment,
      monthlyInterest,
      firstPaymentDate
    );

    setMonthlyPayment(monthlyPayment);
    setArmotizationTable(armotization);
    setTotalInterestPaid(totalInterestPaid);
    resultSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <article className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
      <Box className="text-xl text-slate-700 leading-7 mt-8">
        <Header />
        <div className="mt-14 lg:mx-auto lg:w-full lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-20">
          <div className="lg:col-start-1">
            <article className="w-full">
              <LoanAmountForm
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}
              />
              <OtherDetailsForm
                firstPaymentDate={firstPaymentDate}
                setFirstPaymentDate={setFirstPaymentDate}
                loanTerm={loanTerm}
                setLoanTerm={setLoanTerm}
                interestRate={interestRate}
                setInterestRate={setInterestRate}
                calculateResults={calculateResults}
              />
              {armotizationTable.length > 0 && (
                <Results
                  ref={resultSectionRef}
                  monthlyPayment={monthlyPayment}
                  loanAmount={loanAmount}
                  totalInterestPaid={totalInterestPaid}
                  armotizationTable={armotizationTable}
                />
              )}
            </article>
          </div>

          <div className="mt-12 sm:mt-16 lg:-mt-6 lg:col-start-2">
            <article className="prose prose-slate prose-lg prose-li:my-1 prose-h2:my-6 prose-a:no-underline prose-a:text-primary-600 hover:prose-a:text-primary-800 hover:prose-a:underline mx-auto">
              <h2>What is a mortgage?</h2>
              <p>
                A mortgage is the backbone of buying a home, a fundamental
                financial agreement between you and a lender. It’s not merely
                borrowing money; it’s a structured plan enabling you to purchase
                property now and pay for it over time. Like crafting a fine
                wine, acquiring a home through a mortgage is a process that
                requires time, commitment, and a vision for the future.
              </p>
              <p>
                Imagine a mortgage as your partnership with a lender: They
                provide the substantial upfront funds to purchase your home, and
                you commit to repaying this over time with interest, usually on
                a monthly basis. With terms typically ranging from 10 to 30
                years, the repayment plan is spread out to make homeownership
                immediately accessible, even if the property's full price isn’t
                within your current reach.
              </p>
              <p>
                Moreover, a mortgage is secured by the value of the home itself,
                offering the lender security and giving you the incentive to
                maintain regular payments. This arrangement isn't
                one-size-fits-all; there are several types of mortgages,
                including fixed-rate, where the interest rate stays constant
                over the loan term, or variable-rate, which fluctuates with
                market conditions, each offering different paths to
                homeownership.
              </p>
              <p>
                In selecting a mortgage, you're not just picking a financial
                product, you're laying the foundations for your future. Let our
                calculator guide you through the numbers so you can make an
                informed decision that feels right for you.
              </p>

              <h2>How to use the mortgage calculator?</h2>
              <p>
                Understanding your mortgage options is the first step towards
                homeownership. Here's how to use our mortgage calculator to get
                a clear picture of your potential financial commitment:
              </p>
              <ol>
                <li>
                  <p>
                    <strong>Establish the loan amount</strong>
                    <br />
                    Start by inputting the amount you’re looking to borrow. You
                    can do this by entering the property price and specifying
                    your down payment, allowing the calculator to determine the
                    loan amount for you automatically.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Set the interest rate</strong>
                    <br />
                    Enter the anticipated interest rate for your loan. This rate
                    will significantly affect your monthly payments and the
                    total interest paid over the life of the mortgage, so it's
                    crucial to input an accurate figure.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Decide on the loan term</strong>
                    <br />
                    Choose the duration of your loan. The term can span from a
                    few years to several decades, affecting how much you pay
                    monthly and in total interest.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Specify the first payment date</strong>
                    <br />
                    Select the date when you'll make your first mortgage
                    payment. This sets the timeline for your amortisation
                    schedule and helps calculate when you will have paid off the
                    loan in full.
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    <strong>Analyze the results</strong>
                    <br />
                    With the details entered, the calculator will display:
                  </p>
                  <ul>
                    <li>
                      <strong>Monthly payment</strong>: Your regular payment
                      amount.
                    </li>
                    <li>
                      <strong>Total paid</strong>: The aggregate amount paid
                      over the term, including both principal and interest.
                    </li>
                    <li>
                      <strong>Total interest paid</strong>: The total interest
                      over the term of the loan.
                    </li>
                    <li>
                      <strong>Mortgage payoff date</strong>: The date your
                      mortgage will be fully paid off.
                    </li>
                    <li>
                      <strong>Pie chart</strong>: A pie chart will show the
                      split between interest and principal paid.
                    </li>
                    <li>
                      <strong>Line chart</strong>: A line chart will illustrate
                      the timeline of your remaining balance, principal, and
                      interest paid over time.
                    </li>
                    <li>
                      <strong>Amortisation table</strong>: A detailed table will
                      give you a month-by-month breakdown of each payment,
                      showing exactly how much goes towards the principal versus
                      interest.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                By following these simple steps, you’ll have a comprehensive
                understanding of your mortgage payments and the schedule ahead,
                empowering you to make informed decisions about your future
                home.
              </p>

              <h2>Strategies and tips on using mortgage</h2>
              <p>
                Embarking on the journey of acquiring a mortgage can be as
                exhilarating as it is intricate. Here are some insightful tips
                and strategies that can help you borrow smartly, ensuring your
                mortgage is not a burden but a wise investment towards your
                future home.
              </p>
              <ol>
                <li>
                  <p>
                    <strong>Fixed vs. variable rates</strong>
                    <br />
                    The choice between a fixed and a variable interest rate
                    hinges on your appetite for risk and your financial
                    stability. Fixed-rate mortgages lock in your interest rate,
                    providing a consistent monthly payment that can help with
                    budgeting and long-term planning. Variable rates, tied to
                    market fluctuations, could lower your costs in a declining
                    rate environment but require readiness for potential rate
                    increases. Weigh your options carefully, considering both
                    your current circumstances and future possibilities.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Refinance your mortgage</strong>
                    <br />
                    Refinancing your mortgage can be a savvy financial move when
                    interest rates dip. It can lower your monthly payments, free
                    up cash for other investments, or accelerate your path to
                    paying off your home. However, timing is crucial – ensure
                    the numbers stack up after factoring in fees and the
                    potential extension of your loan’s term.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Keep your credit score healthy</strong>
                    <br />
                    Your credit score is pivotal in the mortgage process,
                    influencing the interest rates offered to you. Before you
                    embark on the mortgage journey, take steps to bolster your
                    credit score: pay down existing debt, keep credit card
                    balances low, and ensure timely payments. A higher credit
                    score could translate to lower interest rates, resulting in
                    substantial savings over the life of your mortgage.
                    <br />
                    To understand how to improve and build your credit score,
                    check out my blog:{' '}
                    <a href="https://www.alphainvestinggroup.com/blogs/emergency-fund">
                      Building Your Safety Net: A Young Adult's Guide to
                      Emergency Funds
                    </a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Down payment is key</strong>
                    <br />A more considerable down payment can pave the way to
                    substantial savings. Not only does it reduce your
                    loan-to-value ratio, leading to better interest rates, but
                    it also can eliminate the need for private mortgage
                    insurance (PMI), which lenders typically require for down
                    payments less than 20%. While it might take longer to save
                    for a larger down payment, the long-term financial benefits
                    are well worth the wait.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Understand the full cost of mortgage</strong>
                    <br />A mortgage is just one piece of the homeownership
                    puzzle. Full financial preparedness includes planning for
                    property taxes, homeowners' insurance, and upkeep costs.
                    These expenses can add up and should be included in your
                    monthly budget to avoid any unwelcome surprises. Knowing the
                    total cost of homeownership gives you a realistic view and
                    helps maintain a stress-free financial future.
                  </p>
                </li>
              </ol>

              <h2>Conclusion and next steps</h2>
              <p>
                As you stand on the brink of property investment, let knowledge
                be your guide and prudence your companion. Armed with our
                calculator and tips, you're closer to making informed choices in
                real estate. Remember, every property investment starts with
                understanding the commitment of a mortgage.
              </p>
              <p>
                If you are interested in diving deeper into real estate
                investing, check out my blog:{' '}
                <a href="https://www.alphainvestinggroup.com/blogs/real-estate-investing-for-beginners">
                  Real estate investing guide for beginners: How to get in real
                  estate investing?
                </a>
              </p>
              <p>
                <em>Disclaimer</em>: This content is for informational purposes
                only and is not intended as professional financial advice. For
                personalized advice, please consult a certified financial
                advisor or mortgage professional.
              </p>
            </article>
          </div>
        </div>
      </Box>
    </article>
  );
};

export default MortgageCalculator;

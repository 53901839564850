export const TOP_10_COINS_ENDPOINT =
  'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false';
export const QUERY_COINS_ENDPOINT = 'https://api.coingecko.com/api/v3/search';
export const COIN_PRICE_ENDPOINT = 'https://api.coingecko.com/api/v3/coins';

export const INITIAL_BASE_CURRENCY = {
  usdValue: null,
  amount: 1,
  id: 'bitcoin',
  name: 'Bitcoin',
  image:
    'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579',
};

export const INITIAL_TARGET_CURRENCY = {
  usdValue: null,
  amount: null,
  id: 'tether',
  name: 'Tether',
  image:
    'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707',
};

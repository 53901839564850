const Header = () => {
  return (
    <>
      <header>
        <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
          Tools
        </span>
        <h1 className="text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 mt-2 mb-8">
          Financial Literacy Test
        </h1>
        <p>
          “Financial literacy is an important part of avoiding financial
          mistakes and planning for a strong, secure financial future,” Tim
          Pawlenty once said.
        </p>
        <p>
          Your level of financial literacy determines your financial situation
          and financial future. According to research published by the American
          Economic Association, the total net worth of a family is positively
          and significantly associated with its financial literacy. The research
          also states that financial literacy is more important than schooling
          for explaining variation in household wealth. It indicates that
          financial literacy is of the essence in building wealth.
        </p>
        <p>So, are you financially literate? Let’s find out!</p>
      </header>
      <section>
        <h2>The financial Literacy test</h2>
        <p>
          To help you assess your financial literacy, we have prepared for you a
          financial literacy test. This test contains 5 questions designed to
          assess your knowledge on these 4 areas:
        </p>
        <ul>
          <li>Risk diversification</li>
          <li>Inflation</li>
          <li>Numeracy</li>
          <li>Compound interest</li>
        </ul>
      </section>
    </>
  );
};

export default Header;

const investmentReturn = [
  100, 102, 100, 94, 99, 96, 93, 93, 98, 97, 102, 106, 104, 105, 94, 100, 101,
  99, 99, 98, 96, 102, 101, 102, 100, 101, 103, 105, 103, 99, 101, 102, 103,
  105, 106, 104, 102, 101, 100, 98, 95, 97, 96, 99, 95, 95, 93, 91, 95, 91, 91,
  92, 90, 91, 93, 94, 94, 91, 90, 86, 86, 86, 80, 79, 80, 78, 82, 86, 87, 88,
  86, 90, 89, 87, 88, 84, 85, 85, 83, 84, 84, 84, 84, 83, 81, 82, 79, 75, 76,
  67, 72, 74, 76, 74, 77, 75, 78, 79, 80, 79, 80, 78, 79, 81, 81, 79, 78, 79,
  78, 76, 77, 76, 79, 81, 81, 80, 80, 78, 77, 78, 75, 74, 73, 77, 75, 74, 71,
  70, 69, 69, 69, 64, 59, 59, 60, 63, 64, 65, 64, 62, 62, 59, 57, 56, 58, 61,
  62, 63, 62, 63, 65, 65, 63, 62, 62, 61, 63, 64, 63, 60, 59, 58, 58, 59, 58,
  58, 58, 62, 60, 61, 60, 62, 62, 65, 65, 66, 65, 67, 69, 69, 69, 68, 68, 69,
  69, 69, 68, 68, 69, 69, 70, 71, 71, 72, 69, 71, 72, 72, 71, 73, 73, 73, 72,
  73, 74, 75, 76, 76, 77, 78, 79, 79, 78, 79, 79, 79, 79, 80, 78, 77, 77, 79,
  79, 79, 79, 77, 76, 76, 76, 78, 78, 79, 79, 79, 78, 77, 76, 75, 76, 74, 74,
  76, 77, 77, 78, 78, 77, 78, 78, 77, 76, 78, 81, 82, 81, 82, 83, 82, 83, 84,
  84, 82, 82, 81, 81, 83, 84, 83, 84, 85, 83, 83, 81, 81, 82, 79, 80, 80, 81,
  80, 83, 83, 83, 83, 84, 83, 83, 84, 85, 86, 86, 85, 85, 85, 84, 84, 86, 86,
  84, 85, 83, 82, 82, 83, 85, 86, 87, 88, 88, 87, 88, 88, 87, 89, 89, 88, 89,
  88, 88, 89, 89, 89, 89, 91, 90, 90, 90, 89, 91, 91, 92, 90, 88, 89, 89, 87,
  87, 86, 88, 88, 86, 86, 89, 89, 88, 90, 90, 91, 90, 92, 91, 93, 94, 95, 95,
  96, 95, 96, 97, 97, 97, 98, 99, 98, 98, 98, 99, 99, 99, 100, 100, 101, 101,
  96, 97, 96, 100, 99, 100, 101, 103, 104, 104, 104, 106, 105, 107, 105, 106,
  104, 104, 106, 108, 106, 101, 99, 101, 100, 103, 102, 101, 103, 106, 106, 108,
  108, 104, 107, 105, 101, 101, 100, 103, 104, 102, 103, 103, 98, 97, 92, 92,
  97, 92, 94, 94, 92, 90, 89, 92, 91, 95, 92, 96, 97, 98, 96, 99, 95, 97, 94,
  94, 91, 89, 88, 86, 87, 87, 87, 90, 90, 90, 89, 86, 87, 87, 84, 76, 62, 65,
  61, 67, 65, 61, 56, 62, 61, 61, 62, 61, 65, 62, 59, 58, 57, 60, 57, 53, 51,
  47, 52, 53, 57, 58, 59, 60, 60, 61, 64, 61, 62, 64, 65, 66, 64, 64, 62, 61,
  65, 68, 69, 70, 70, 71, 71, 71, 72, 74, 72, 71, 74, 75, 75, 72, 74, 76, 76,
  76, 77, 77, 76, 78, 77, 79, 79, 76, 74, 74, 75, 77, 77, 79, 80, 80, 81, 82,
  83, 83, 84, 82, 77, 79, 75, 76, 74, 76, 78, 75, 71, 75, 74, 76, 76, 78, 75,
  74, 74, 77, 77, 78, 80, 80, 81, 82, 82, 82, 85, 83, 83, 83, 85, 86, 86, 87,
  87, 88, 90, 89, 89, 91, 92, 93, 92, 92, 90, 89, 91, 92, 92, 92, 93, 95, 93,
  93, 92, 92, 90, 88, 88, 88, 93, 93, 91, 93, 90, 83, 82, 78, 82, 81, 80, 84,
  79, 78, 80, 85, 86, 89, 87, 88, 84, 80, 86, 87, 85, 88, 87, 89, 89, 91, 91,
  93, 93, 94, 95, 95, 95, 97, 97, 98, 97, 95, 96, 97, 95, 94, 90, 91, 89, 92,
  93, 93, 94, 94, 94, 95, 96, 96, 98, 98, 98, 98, 100, 102, 101, 100, 101, 99,
  99, 98, 98, 96, 94, 98, 98, 98, 98, 99, 97, 102, 102, 103, 104, 105, 105, 105,
  105, 105, 108, 108, 108, 109, 108, 110, 108, 110, 112, 113, 116, 114, 113,
  114, 113, 110, 111, 113, 117, 117, 117, 119, 117, 115, 115, 113, 115, 117,
  119, 117, 117, 118, 121, 122, 122, 123, 125, 125, 125, 125, 123, 126, 128,
  127, 128, 128, 124, 124, 125, 128, 127, 129, 130, 128, 129, 129, 129, 126,
  129, 129, 131, 130, 130, 132, 133, 135, 134, 136, 136, 138, 136, 137, 137,
  134, 134, 136, 138, 139, 139, 138, 139, 138, 137, 132, 131, 136, 140, 141,
  142, 143, 143, 144, 139, 144, 145, 143, 142, 140, 142, 138, 143, 145, 146,
  146, 144, 142, 146, 143, 143, 146, 144, 147, 146, 147, 147, 147, 146, 145,
  145, 146, 146, 144, 144, 148, 144, 146, 144, 145, 137, 138, 133, 136, 136,
  134, 135, 140, 141, 144, 144, 146, 140, 145, 145, 145, 140, 139, 143, 142,
  133, 130, 132, 135, 130, 129, 133, 135, 139, 140, 142, 141, 144, 142, 144,
  145, 143, 143, 142, 142, 146, 146, 145, 144, 141, 146, 148, 150, 151, 151,
  151, 152, 152, 150, 151, 148, 148, 150, 150, 149, 148, 149, 148, 145, 150,
  151, 154, 152, 157, 157, 157, 155, 158, 158, 158, 159, 159, 161, 163, 164,
  165, 165, 165, 163, 164, 163, 162, 163, 165, 166, 166, 165, 168, 169, 169,
  169, 169, 168, 168, 171, 172, 171, 172, 169, 168, 169, 172, 171, 173, 174,
  175, 177, 177, 179, 179, 180, 179, 179, 181, 183, 184, 186, 186, 185, 190,
  193, 195, 199, 192, 182, 190, 191, 187, 193, 191, 180, 183, 181, 184, 185,
  185, 185, 189, 188, 189, 190, 193, 193, 191, 189, 191, 194, 194, 196, 197,
  197, 198, 199, 201, 199, 202, 203, 202, 200, 192, 192, 184, 189, 193, 190,
  183, 191, 183, 180, 168, 172, 176, 180, 185, 185, 188, 188, 193, 194, 195,
  190, 196, 194, 197, 201, 202, 202, 204, 204, 200, 198, 196, 191, 199, 200,
  205, 204, 207, 209, 206, 210, 203, 202, 200, 198, 203, 207, 209, 208, 205,
  205, 206, 207, 210, 213, 215, 216, 216, 218, 218, 220, 223, 225, 224, 227,
  231, 229, 224, 231, 234, 232, 205, 206, 188, 160, 176, 173, 194, 199, 197,
  196, 203, 199, 205, 211, 222, 211, 215, 209, 217, 221, 224, 223, 227, 232,
  234, 236, 243, 238, 232, 230, 229, 232, 241, 242, 240, 227, 243, 249, 247,
  252, 257, 254, 257, 257, 261, 265, 261, 266, 258, 270, 273, 271, 264, 267,
  274, 271, 276, 279, 286, 290, 290, 290, 294, 290, 288, 292, 293, 295, 289,
  297, 302, 303, 300, 306, 305, 308, 310, 308, 313, 315, 309, 308, 309, 302,
  305, 310, 315, 319, 326, 325, 326, 319, 315, 327, 321, 328, 331, 324, 323,
  305, 307, 312, 307, 302, 304, 300, 292, 310, 315, 315, 311, 305, 296, 287,
  286, 279, 271, 288, 285, 271, 255, 271, 265, 271, 268, 275, 287, 288, 297,
  293, 281, 272, 282, 273,
];

const date = [
  '02-1-2000',
  '09-1-2000',
  '16-1-2000',
  '23-1-2000',
  '30-1-2000',
  '06-2-2000',
  '13-2-2000',
  '20-2-2000',
  '27-2-2000',
  '05-3-2000',
  '12-3-2000',
  '19-3-2000',
  '26-3-2000',
  '02-4-2000',
  '09-4-2000',
  '16-4-2000',
  '23-4-2000',
  '30-4-2000',
  '07-5-2000',
  '14-5-2000',
  '21-5-2000',
  '28-5-2000',
  '04-6-2000',
  '11-6-2000',
  '18-6-2000',
  '25-6-2000',
  '02-7-2000',
  '09-7-2000',
  '16-7-2000',
  '23-7-2000',
  '30-7-2000',
  '06-8-2000',
  '13-8-2000',
  '20-8-2000',
  '27-8-2000',
  '03-9-2000',
  '10-9-2000',
  '17-9-2000',
  '24-9-2000',
  '01-10-2000',
  '08-10-2000',
  '15-10-2000',
  '22-10-2000',
  '29-10-2000',
  '05-11-2000',
  '12-11-2000',
  '19-11-2000',
  '26-11-2000',
  '03-12-2000',
  '10-12-2000',
  '17-12-2000',
  '24-12-2000',
  '31-12-2000',
  '07-1-2001',
  '14-1-2001',
  '21-1-2001',
  '28-1-2001',
  '04-2-2001',
  '11-2-2001',
  '18-2-2001',
  '25-2-2001',
  '04-3-2001',
  '11-3-2001',
  '18-3-2001',
  '25-3-2001',
  '01-4-2001',
  '08-4-2001',
  '15-4-2001',
  '22-4-2001',
  '29-4-2001',
  '06-5-2001',
  '13-5-2001',
  '20-5-2001',
  '27-5-2001',
  '03-6-2001',
  '10-6-2001',
  '17-6-2001',
  '24-6-2001',
  '01-7-2001',
  '08-7-2001',
  '15-7-2001',
  '22-7-2001',
  '29-7-2001',
  '05-8-2001',
  '12-8-2001',
  '19-8-2001',
  '26-8-2001',
  '02-9-2001',
  '09-9-2001',
  '16-9-2001',
  '23-9-2001',
  '30-9-2001',
  '07-10-2001',
  '14-10-2001',
  '21-10-2001',
  '28-10-2001',
  '04-11-2001',
  '11-11-2001',
  '18-11-2001',
  '25-11-2001',
  '02-12-2001',
  '09-12-2001',
  '16-12-2001',
  '23-12-2001',
  '30-12-2001',
  '06-1-2002',
  '13-1-2002',
  '20-1-2002',
  '27-1-2002',
  '03-2-2002',
  '10-2-2002',
  '17-2-2002',
  '24-2-2002',
  '03-3-2002',
  '10-3-2002',
  '17-3-2002',
  '24-3-2002',
  '31-3-2002',
  '07-4-2002',
  '14-4-2002',
  '21-4-2002',
  '28-4-2002',
  '05-5-2002',
  '12-5-2002',
  '19-5-2002',
  '26-5-2002',
  '02-6-2002',
  '09-6-2002',
  '16-6-2002',
  '23-6-2002',
  '30-6-2002',
  '07-7-2002',
  '14-7-2002',
  '21-7-2002',
  '28-7-2002',
  '04-8-2002',
  '11-8-2002',
  '18-8-2002',
  '25-8-2002',
  '01-9-2002',
  '08-9-2002',
  '15-9-2002',
  '22-9-2002',
  '29-9-2002',
  '06-10-2002',
  '13-10-2002',
  '20-10-2002',
  '27-10-2002',
  '03-11-2002',
  '10-11-2002',
  '17-11-2002',
  '24-11-2002',
  '01-12-2002',
  '08-12-2002',
  '15-12-2002',
  '22-12-2002',
  '29-12-2002',
  '05-1-2003',
  '12-1-2003',
  '19-1-2003',
  '26-1-2003',
  '02-2-2003',
  '09-2-2003',
  '16-2-2003',
  '23-2-2003',
  '02-3-2003',
  '09-3-2003',
  '16-3-2003',
  '23-3-2003',
  '30-3-2003',
  '06-4-2003',
  '13-4-2003',
  '20-4-2003',
  '27-4-2003',
  '04-5-2003',
  '11-5-2003',
  '18-5-2003',
  '25-5-2003',
  '01-6-2003',
  '08-6-2003',
  '15-6-2003',
  '22-6-2003',
  '29-6-2003',
  '06-7-2003',
  '13-7-2003',
  '20-7-2003',
  '27-7-2003',
  '03-8-2003',
  '10-8-2003',
  '17-8-2003',
  '24-8-2003',
  '31-8-2003',
  '07-9-2003',
  '14-9-2003',
  '21-9-2003',
  '28-9-2003',
  '05-10-2003',
  '12-10-2003',
  '19-10-2003',
  '26-10-2003',
  '02-11-2003',
  '09-11-2003',
  '16-11-2003',
  '23-11-2003',
  '30-11-2003',
  '07-12-2003',
  '14-12-2003',
  '21-12-2003',
  '28-12-2003',
  '04-1-2004',
  '11-1-2004',
  '18-1-2004',
  '25-1-2004',
  '01-2-2004',
  '08-2-2004',
  '15-2-2004',
  '22-2-2004',
  '29-2-2004',
  '07-3-2004',
  '14-3-2004',
  '21-3-2004',
  '28-3-2004',
  '04-4-2004',
  '11-4-2004',
  '18-4-2004',
  '25-4-2004',
  '02-5-2004',
  '09-5-2004',
  '16-5-2004',
  '23-5-2004',
  '30-5-2004',
  '06-6-2004',
  '13-6-2004',
  '20-6-2004',
  '27-6-2004',
  '04-7-2004',
  '11-7-2004',
  '18-7-2004',
  '25-7-2004',
  '01-8-2004',
  '08-8-2004',
  '15-8-2004',
  '22-8-2004',
  '29-8-2004',
  '05-9-2004',
  '12-9-2004',
  '19-9-2004',
  '26-9-2004',
  '03-10-2004',
  '10-10-2004',
  '17-10-2004',
  '24-10-2004',
  '31-10-2004',
  '07-11-2004',
  '14-11-2004',
  '21-11-2004',
  '28-11-2004',
  '05-12-2004',
  '12-12-2004',
  '19-12-2004',
  '26-12-2004',
  '02-1-2005',
  '09-1-2005',
  '16-1-2005',
  '23-1-2005',
  '30-1-2005',
  '06-2-2005',
  '13-2-2005',
  '20-2-2005',
  '27-2-2005',
  '06-3-2005',
  '13-3-2005',
  '20-3-2005',
  '27-3-2005',
  '03-4-2005',
  '10-4-2005',
  '17-4-2005',
  '24-4-2005',
  '01-5-2005',
  '08-5-2005',
  '15-5-2005',
  '22-5-2005',
  '29-5-2005',
  '05-6-2005',
  '12-6-2005',
  '19-6-2005',
  '26-6-2005',
  '03-7-2005',
  '10-7-2005',
  '17-7-2005',
  '24-7-2005',
  '31-7-2005',
  '07-8-2005',
  '14-8-2005',
  '21-8-2005',
  '28-8-2005',
  '04-9-2005',
  '11-9-2005',
  '18-9-2005',
  '25-9-2005',
  '02-10-2005',
  '09-10-2005',
  '16-10-2005',
  '23-10-2005',
  '30-10-2005',
  '06-11-2005',
  '13-11-2005',
  '20-11-2005',
  '27-11-2005',
  '04-12-2005',
  '11-12-2005',
  '18-12-2005',
  '25-12-2005',
  '01-1-2006',
  '08-1-2006',
  '15-1-2006',
  '22-1-2006',
  '29-1-2006',
  '05-2-2006',
  '12-2-2006',
  '19-2-2006',
  '26-2-2006',
  '05-3-2006',
  '12-3-2006',
  '19-3-2006',
  '26-3-2006',
  '02-4-2006',
  '09-4-2006',
  '16-4-2006',
  '23-4-2006',
  '30-4-2006',
  '07-5-2006',
  '14-5-2006',
  '21-5-2006',
  '28-5-2006',
  '04-6-2006',
  '11-6-2006',
  '18-6-2006',
  '25-6-2006',
  '02-7-2006',
  '09-7-2006',
  '16-7-2006',
  '23-7-2006',
  '30-7-2006',
  '06-8-2006',
  '13-8-2006',
  '20-8-2006',
  '27-8-2006',
  '03-9-2006',
  '10-9-2006',
  '17-9-2006',
  '24-9-2006',
  '01-10-2006',
  '08-10-2006',
  '15-10-2006',
  '22-10-2006',
  '29-10-2006',
  '05-11-2006',
  '12-11-2006',
  '19-11-2006',
  '26-11-2006',
  '03-12-2006',
  '10-12-2006',
  '17-12-2006',
  '24-12-2006',
  '31-12-2006',
  '07-1-2007',
  '14-1-2007',
  '21-1-2007',
  '28-1-2007',
  '04-2-2007',
  '11-2-2007',
  '18-2-2007',
  '25-2-2007',
  '04-3-2007',
  '11-3-2007',
  '18-3-2007',
  '25-3-2007',
  '01-4-2007',
  '08-4-2007',
  '15-4-2007',
  '22-4-2007',
  '29-4-2007',
  '06-5-2007',
  '13-5-2007',
  '20-5-2007',
  '27-5-2007',
  '03-6-2007',
  '10-6-2007',
  '17-6-2007',
  '24-6-2007',
  '01-7-2007',
  '08-7-2007',
  '15-7-2007',
  '22-7-2007',
  '29-7-2007',
  '05-8-2007',
  '12-8-2007',
  '19-8-2007',
  '26-8-2007',
  '02-9-2007',
  '09-9-2007',
  '16-9-2007',
  '23-9-2007',
  '30-9-2007',
  '07-10-2007',
  '14-10-2007',
  '21-10-2007',
  '28-10-2007',
  '04-11-2007',
  '11-11-2007',
  '18-11-2007',
  '25-11-2007',
  '02-12-2007',
  '09-12-2007',
  '16-12-2007',
  '23-12-2007',
  '30-12-2007',
  '06-1-2008',
  '13-1-2008',
  '20-1-2008',
  '27-1-2008',
  '03-2-2008',
  '10-2-2008',
  '17-2-2008',
  '24-2-2008',
  '02-3-2008',
  '09-3-2008',
  '16-3-2008',
  '23-3-2008',
  '30-3-2008',
  '06-4-2008',
  '13-4-2008',
  '20-4-2008',
  '27-4-2008',
  '04-5-2008',
  '11-5-2008',
  '18-5-2008',
  '25-5-2008',
  '01-6-2008',
  '08-6-2008',
  '15-6-2008',
  '22-6-2008',
  '29-6-2008',
  '06-7-2008',
  '13-7-2008',
  '20-7-2008',
  '27-7-2008',
  '03-8-2008',
  '10-8-2008',
  '17-8-2008',
  '24-8-2008',
  '31-8-2008',
  '07-9-2008',
  '14-9-2008',
  '21-9-2008',
  '28-9-2008',
  '05-10-2008',
  '12-10-2008',
  '19-10-2008',
  '26-10-2008',
  '02-11-2008',
  '09-11-2008',
  '16-11-2008',
  '23-11-2008',
  '30-11-2008',
  '07-12-2008',
  '14-12-2008',
  '21-12-2008',
  '28-12-2008',
  '04-1-2009',
  '11-1-2009',
  '18-1-2009',
  '25-1-2009',
  '01-2-2009',
  '08-2-2009',
  '15-2-2009',
  '22-2-2009',
  '01-3-2009',
  '08-3-2009',
  '15-3-2009',
  '22-3-2009',
  '29-3-2009',
  '05-4-2009',
  '12-4-2009',
  '19-4-2009',
  '26-4-2009',
  '03-5-2009',
  '10-5-2009',
  '17-5-2009',
  '24-5-2009',
  '31-5-2009',
  '07-6-2009',
  '14-6-2009',
  '21-6-2009',
  '28-6-2009',
  '05-7-2009',
  '12-7-2009',
  '19-7-2009',
  '26-7-2009',
  '02-8-2009',
  '09-8-2009',
  '16-8-2009',
  '23-8-2009',
  '30-8-2009',
  '06-9-2009',
  '13-9-2009',
  '20-9-2009',
  '27-9-2009',
  '04-10-2009',
  '11-10-2009',
  '18-10-2009',
  '25-10-2009',
  '01-11-2009',
  '08-11-2009',
  '15-11-2009',
  '22-11-2009',
  '29-11-2009',
  '06-12-2009',
  '13-12-2009',
  '20-12-2009',
  '27-12-2009',
  '03-1-2010',
  '10-1-2010',
  '17-1-2010',
  '24-1-2010',
  '31-1-2010',
  '07-2-2010',
  '14-2-2010',
  '21-2-2010',
  '28-2-2010',
  '07-3-2010',
  '14-3-2010',
  '21-3-2010',
  '28-3-2010',
  '04-4-2010',
  '11-4-2010',
  '18-4-2010',
  '25-4-2010',
  '02-5-2010',
  '09-5-2010',
  '16-5-2010',
  '23-5-2010',
  '30-5-2010',
  '06-6-2010',
  '13-6-2010',
  '20-6-2010',
  '27-6-2010',
  '04-7-2010',
  '11-7-2010',
  '18-7-2010',
  '25-7-2010',
  '01-8-2010',
  '08-8-2010',
  '15-8-2010',
  '22-8-2010',
  '29-8-2010',
  '05-9-2010',
  '12-9-2010',
  '19-9-2010',
  '26-9-2010',
  '03-10-2010',
  '10-10-2010',
  '17-10-2010',
  '24-10-2010',
  '31-10-2010',
  '07-11-2010',
  '14-11-2010',
  '21-11-2010',
  '28-11-2010',
  '05-12-2010',
  '12-12-2010',
  '19-12-2010',
  '26-12-2010',
  '02-1-2011',
  '09-1-2011',
  '16-1-2011',
  '23-1-2011',
  '30-1-2011',
  '06-2-2011',
  '13-2-2011',
  '20-2-2011',
  '27-2-2011',
  '06-3-2011',
  '13-3-2011',
  '20-3-2011',
  '27-3-2011',
  '03-4-2011',
  '10-4-2011',
  '17-4-2011',
  '24-4-2011',
  '01-5-2011',
  '08-5-2011',
  '15-5-2011',
  '22-5-2011',
  '29-5-2011',
  '05-6-2011',
  '12-6-2011',
  '19-6-2011',
  '26-6-2011',
  '03-7-2011',
  '10-7-2011',
  '17-7-2011',
  '24-7-2011',
  '31-7-2011',
  '07-8-2011',
  '14-8-2011',
  '21-8-2011',
  '28-8-2011',
  '04-9-2011',
  '11-9-2011',
  '18-9-2011',
  '25-9-2011',
  '02-10-2011',
  '09-10-2011',
  '16-10-2011',
  '23-10-2011',
  '30-10-2011',
  '06-11-2011',
  '13-11-2011',
  '20-11-2011',
  '27-11-2011',
  '04-12-2011',
  '11-12-2011',
  '18-12-2011',
  '25-12-2011',
  '01-1-2012',
  '08-1-2012',
  '15-1-2012',
  '22-1-2012',
  '29-1-2012',
  '05-2-2012',
  '12-2-2012',
  '19-2-2012',
  '26-2-2012',
  '04-3-2012',
  '11-3-2012',
  '18-3-2012',
  '25-3-2012',
  '01-4-2012',
  '08-4-2012',
  '15-4-2012',
  '22-4-2012',
  '29-4-2012',
  '06-5-2012',
  '13-5-2012',
  '20-5-2012',
  '27-5-2012',
  '03-6-2012',
  '10-6-2012',
  '17-6-2012',
  '24-6-2012',
  '01-7-2012',
  '08-7-2012',
  '15-7-2012',
  '22-7-2012',
  '29-7-2012',
  '05-8-2012',
  '12-8-2012',
  '19-8-2012',
  '26-8-2012',
  '02-9-2012',
  '09-9-2012',
  '16-9-2012',
  '23-9-2012',
  '30-9-2012',
  '07-10-2012',
  '14-10-2012',
  '21-10-2012',
  '28-10-2012',
  '04-11-2012',
  '11-11-2012',
  '18-11-2012',
  '25-11-2012',
  '02-12-2012',
  '09-12-2012',
  '16-12-2012',
  '23-12-2012',
  '30-12-2012',
  '06-1-2013',
  '13-1-2013',
  '20-1-2013',
  '27-1-2013',
  '03-2-2013',
  '10-2-2013',
  '17-2-2013',
  '24-2-2013',
  '03-3-2013',
  '10-3-2013',
  '17-3-2013',
  '24-3-2013',
  '31-3-2013',
  '07-4-2013',
  '14-4-2013',
  '21-4-2013',
  '28-4-2013',
  '05-5-2013',
  '12-5-2013',
  '19-5-2013',
  '26-5-2013',
  '02-6-2013',
  '09-6-2013',
  '16-6-2013',
  '23-6-2013',
  '30-6-2013',
  '07-7-2013',
  '14-7-2013',
  '21-7-2013',
  '28-7-2013',
  '04-8-2013',
  '11-8-2013',
  '18-8-2013',
  '25-8-2013',
  '01-9-2013',
  '08-9-2013',
  '15-9-2013',
  '22-9-2013',
  '29-9-2013',
  '06-10-2013',
  '13-10-2013',
  '20-10-2013',
  '27-10-2013',
  '03-11-2013',
  '10-11-2013',
  '17-11-2013',
  '24-11-2013',
  '01-12-2013',
  '08-12-2013',
  '15-12-2013',
  '22-12-2013',
  '29-12-2013',
  '05-1-2014',
  '12-1-2014',
  '19-1-2014',
  '26-1-2014',
  '02-2-2014',
  '09-2-2014',
  '16-2-2014',
  '23-2-2014',
  '02-3-2014',
  '09-3-2014',
  '16-3-2014',
  '23-3-2014',
  '30-3-2014',
  '06-4-2014',
  '13-4-2014',
  '20-4-2014',
  '27-4-2014',
  '04-5-2014',
  '11-5-2014',
  '18-5-2014',
  '25-5-2014',
  '01-6-2014',
  '08-6-2014',
  '15-6-2014',
  '22-6-2014',
  '29-6-2014',
  '06-7-2014',
  '13-7-2014',
  '20-7-2014',
  '27-7-2014',
  '03-8-2014',
  '10-8-2014',
  '17-8-2014',
  '24-8-2014',
  '31-8-2014',
  '07-9-2014',
  '14-9-2014',
  '21-9-2014',
  '28-9-2014',
  '05-10-2014',
  '12-10-2014',
  '19-10-2014',
  '26-10-2014',
  '02-11-2014',
  '09-11-2014',
  '16-11-2014',
  '23-11-2014',
  '30-11-2014',
  '07-12-2014',
  '14-12-2014',
  '21-12-2014',
  '28-12-2014',
  '04-1-2015',
  '11-1-2015',
  '18-1-2015',
  '25-1-2015',
  '01-2-2015',
  '08-2-2015',
  '15-2-2015',
  '22-2-2015',
  '01-3-2015',
  '08-3-2015',
  '15-3-2015',
  '22-3-2015',
  '29-3-2015',
  '05-4-2015',
  '12-4-2015',
  '19-4-2015',
  '26-4-2015',
  '03-5-2015',
  '10-5-2015',
  '17-5-2015',
  '24-5-2015',
  '31-5-2015',
  '07-6-2015',
  '14-6-2015',
  '21-6-2015',
  '28-6-2015',
  '05-7-2015',
  '12-7-2015',
  '19-7-2015',
  '26-7-2015',
  '02-8-2015',
  '09-8-2015',
  '16-8-2015',
  '23-8-2015',
  '30-8-2015',
  '06-9-2015',
  '13-9-2015',
  '20-9-2015',
  '27-9-2015',
  '04-10-2015',
  '11-10-2015',
  '18-10-2015',
  '25-10-2015',
  '01-11-2015',
  '08-11-2015',
  '15-11-2015',
  '22-11-2015',
  '29-11-2015',
  '06-12-2015',
  '13-12-2015',
  '20-12-2015',
  '27-12-2015',
  '03-1-2016',
  '10-1-2016',
  '17-1-2016',
  '24-1-2016',
  '31-1-2016',
  '07-2-2016',
  '14-2-2016',
  '21-2-2016',
  '28-2-2016',
  '06-3-2016',
  '13-3-2016',
  '20-3-2016',
  '27-3-2016',
  '03-4-2016',
  '10-4-2016',
  '17-4-2016',
  '24-4-2016',
  '01-5-2016',
  '08-5-2016',
  '15-5-2016',
  '22-5-2016',
  '29-5-2016',
  '05-6-2016',
  '12-6-2016',
  '19-6-2016',
  '26-6-2016',
  '03-7-2016',
  '10-7-2016',
  '17-7-2016',
  '24-7-2016',
  '31-7-2016',
  '07-8-2016',
  '14-8-2016',
  '21-8-2016',
  '28-8-2016',
  '04-9-2016',
  '11-9-2016',
  '18-9-2016',
  '25-9-2016',
  '02-10-2016',
  '09-10-2016',
  '16-10-2016',
  '23-10-2016',
  '30-10-2016',
  '06-11-2016',
  '13-11-2016',
  '20-11-2016',
  '27-11-2016',
  '04-12-2016',
  '11-12-2016',
  '18-12-2016',
  '25-12-2016',
  '01-1-2017',
  '08-1-2017',
  '15-1-2017',
  '22-1-2017',
  '29-1-2017',
  '05-2-2017',
  '12-2-2017',
  '19-2-2017',
  '26-2-2017',
  '05-3-2017',
  '12-3-2017',
  '19-3-2017',
  '26-3-2017',
  '02-4-2017',
  '09-4-2017',
  '16-4-2017',
  '23-4-2017',
  '30-4-2017',
  '07-5-2017',
  '14-5-2017',
  '21-5-2017',
  '28-5-2017',
  '04-6-2017',
  '11-6-2017',
  '18-6-2017',
  '25-6-2017',
  '02-7-2017',
  '09-7-2017',
  '16-7-2017',
  '23-7-2017',
  '30-7-2017',
  '06-8-2017',
  '13-8-2017',
  '20-8-2017',
  '27-8-2017',
  '03-9-2017',
  '10-9-2017',
  '17-9-2017',
  '24-9-2017',
  '01-10-2017',
  '08-10-2017',
  '15-10-2017',
  '22-10-2017',
  '29-10-2017',
  '05-11-2017',
  '12-11-2017',
  '19-11-2017',
  '26-11-2017',
  '03-12-2017',
  '10-12-2017',
  '17-12-2017',
  '24-12-2017',
  '31-12-2017',
  '07-1-2018',
  '14-1-2018',
  '21-1-2018',
  '28-1-2018',
  '04-2-2018',
  '11-2-2018',
  '18-2-2018',
  '25-2-2018',
  '04-3-2018',
  '11-3-2018',
  '18-3-2018',
  '25-3-2018',
  '01-4-2018',
  '08-4-2018',
  '15-4-2018',
  '22-4-2018',
  '29-4-2018',
  '06-5-2018',
  '13-5-2018',
  '20-5-2018',
  '27-5-2018',
  '03-6-2018',
  '10-6-2018',
  '17-6-2018',
  '24-6-2018',
  '01-7-2018',
  '08-7-2018',
  '15-7-2018',
  '22-7-2018',
  '29-7-2018',
  '05-8-2018',
  '12-8-2018',
  '19-8-2018',
  '26-8-2018',
  '02-9-2018',
  '09-9-2018',
  '16-9-2018',
  '23-9-2018',
  '30-9-2018',
  '07-10-2018',
  '14-10-2018',
  '21-10-2018',
  '28-10-2018',
  '04-11-2018',
  '11-11-2018',
  '18-11-2018',
  '25-11-2018',
  '02-12-2018',
  '09-12-2018',
  '16-12-2018',
  '23-12-2018',
  '30-12-2018',
  '06-1-2019',
  '13-1-2019',
  '20-1-2019',
  '27-1-2019',
  '03-2-2019',
  '10-2-2019',
  '17-2-2019',
  '24-2-2019',
  '03-3-2019',
  '10-3-2019',
  '17-3-2019',
  '24-3-2019',
  '31-3-2019',
  '07-4-2019',
  '14-4-2019',
  '21-4-2019',
  '28-4-2019',
  '05-5-2019',
  '12-5-2019',
  '19-5-2019',
  '26-5-2019',
  '02-6-2019',
  '09-6-2019',
  '16-6-2019',
  '23-6-2019',
  '30-6-2019',
  '07-7-2019',
  '14-7-2019',
  '21-7-2019',
  '28-7-2019',
  '04-8-2019',
  '11-8-2019',
  '18-8-2019',
  '25-8-2019',
  '01-9-2019',
  '08-9-2019',
  '15-9-2019',
  '22-9-2019',
  '29-9-2019',
  '06-10-2019',
  '13-10-2019',
  '20-10-2019',
  '27-10-2019',
  '03-11-2019',
  '10-11-2019',
  '17-11-2019',
  '24-11-2019',
  '01-12-2019',
  '08-12-2019',
  '15-12-2019',
  '22-12-2019',
  '29-12-2019',
  '05-1-2020',
  '12-1-2020',
  '19-1-2020',
  '26-1-2020',
  '02-2-2020',
  '09-2-2020',
  '16-2-2020',
  '23-2-2020',
  '01-3-2020',
  '08-3-2020',
  '15-3-2020',
  '22-3-2020',
  '29-3-2020',
  '05-4-2020',
  '12-4-2020',
  '19-4-2020',
  '26-4-2020',
  '03-5-2020',
  '10-5-2020',
  '17-5-2020',
  '24-5-2020',
  '31-5-2020',
  '07-6-2020',
  '14-6-2020',
  '21-6-2020',
  '28-6-2020',
  '05-7-2020',
  '12-7-2020',
  '19-7-2020',
  '26-7-2020',
  '02-8-2020',
  '09-8-2020',
  '16-8-2020',
  '23-8-2020',
  '30-8-2020',
  '06-9-2020',
  '13-9-2020',
  '20-9-2020',
  '27-9-2020',
  '04-10-2020',
  '11-10-2020',
  '18-10-2020',
  '25-10-2020',
  '01-11-2020',
  '08-11-2020',
  '15-11-2020',
  '22-11-2020',
  '29-11-2020',
  '06-12-2020',
  '13-12-2020',
  '20-12-2020',
  '27-12-2020',
  '03-1-2021',
  '10-1-2021',
  '17-1-2021',
  '24-1-2021',
  '31-1-2021',
  '07-2-2021',
  '14-2-2021',
  '21-2-2021',
  '28-2-2021',
  '07-3-2021',
  '14-3-2021',
  '21-3-2021',
  '28-3-2021',
  '04-4-2021',
  '11-4-2021',
  '18-4-2021',
  '25-4-2021',
  '02-5-2021',
  '09-5-2021',
  '16-5-2021',
  '23-5-2021',
  '30-5-2021',
  '06-6-2021',
  '13-6-2021',
  '20-6-2021',
  '27-6-2021',
  '04-7-2021',
  '11-7-2021',
  '18-7-2021',
  '25-7-2021',
  '01-8-2021',
  '08-8-2021',
  '15-8-2021',
  '22-8-2021',
  '29-8-2021',
  '05-9-2021',
  '12-9-2021',
  '19-9-2021',
  '26-9-2021',
  '03-10-2021',
  '10-10-2021',
  '17-10-2021',
  '24-10-2021',
  '31-10-2021',
  '07-11-2021',
  '14-11-2021',
  '21-11-2021',
  '28-11-2021',
  '05-12-2021',
  '12-12-2021',
  '19-12-2021',
  '26-12-2021',
  '02-1-2022',
  '09-1-2022',
  '16-1-2022',
  '23-1-2022',
  '30-1-2022',
  '06-2-2022',
  '13-2-2022',
  '20-2-2022',
  '27-2-2022',
  '06-3-2022',
  '13-3-2022',
  '20-3-2022',
  '27-3-2022',
  '03-4-2022',
  '10-4-2022',
  '17-4-2022',
  '24-4-2022',
  '01-5-2022',
  '08-5-2022',
  '15-5-2022',
  '22-5-2022',
  '29-5-2022',
  '05-6-2022',
  '12-6-2022',
  '19-6-2022',
  '26-6-2022',
  '03-7-2022',
  '10-7-2022',
  '17-7-2022',
  '24-7-2022',
  '31-7-2022',
  '07-8-2022',
  '14-8-2022',
  '21-8-2022',
  '28-8-2022',
  '04-9-2022',
  '11-9-2022',
];

export { investmentReturn, date };

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';

const OtherDetailsForm = ({
  firstPaymentDate,
  setFirstPaymentDate,
  interestRate,
  setInterestRate,
  loanTerm,
  setLoanTerm,
  calculateResults,
}) => {
  return (
    <Card
      sx={{
        minWidth: '534px',
        marginBottom: '32px',
        padding: '48px',
        borderRadius: '16px',
        boxShadow:
          '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box mt={2}>
        <Typography
          component="p"
          fontWeight={700}
          fontSize="20px"
          fontFamily="inherit"
          mb={2}
        >
          What is the interest rate? (%)
        </Typography>
        <TextField
          value={interestRate}
          onChange={e => setInterestRate(Number.parseFloat(e.target.value))}
          hiddenLabel
          type="number"
          variant="outlined"
          fullWidth
          size="normal"
          placeholder="5%"
        />
      </Box>
      <Box mt={2}>
        <Typography
          component="p"
          fontWeight={700}
          fontSize="20px"
          fontFamily="inherit"
          mb={2}
        >
          How long is the loan term? (years)
        </Typography>
        <TextField
          value={loanTerm}
          onChange={e => setLoanTerm(Number.parseFloat(e.target.value))}
          hiddenLabel
          type="number"
          variant="outlined"
          fullWidth
          size="normal"
          placeholder="20 years"
        />
      </Box>
      <Box mt={2}>
        <Typography
          component="p"
          fontWeight={700}
          fontSize="20px"
          fontFamily="inherit"
          mb={2}
        >
          When is the first payment date?
        </Typography>
        <DatePicker
          value={firstPaymentDate}
          onChange={date => setFirstPaymentDate(date)}
          sx={{ width: '100%' }}
        />
      </Box>

      <Box mt={3}>
        <Button
          mt={5}
          variant="contained"
          fullWidth
          onClick={calculateResults}
          size="large"
        >
          Get my results
        </Button>
      </Box>
    </Card>
  );
};

export default OtherDetailsForm;

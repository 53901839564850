import LineChart from '@cloudscape-design/components/line-chart';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import { addMonths } from 'date-fns';

const InvestmentWorthChart = ({
  indexPriceHistory,
  investmentWorth,
  startDate,
  indexType,
}) => {
  const indexPriceHistoryData = [];
  const investmentWorthData = [];
  let endDate = startDate;

  for (let i = 0; i < indexPriceHistory.length; i++) {
    endDate = new Date(addMonths(new Date(startDate), i));
    indexPriceHistoryData.push({
      x: new Date(addMonths(new Date(startDate), i)),
      y: indexPriceHistory[i],
    });
    investmentWorthData.push({
      x: new Date(addMonths(new Date(startDate), i)),
      y: investmentWorth[i],
    });
  }

  let chartData;
  if (indexPriceHistoryData.length === 0 && investmentWorthData.length === 0) {
    chartData = [];
  } else {
    chartData = [
      {
        title: 'Investment Worth',
        type: 'line',
        data: investmentWorthData,
        valueFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? '$' + (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? '$' + (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? '$' + (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      },
      {
        title: `${indexType === 'spx' ? 'S&P 500' : 'Nasdaq'} price`,
        type: 'line',
        data: indexPriceHistoryData,
        valueFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? '$' + (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? '$' + (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? '$' + (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      },
    ];
  }

  return (
    <LineChart
      series={chartData}
      xDomain={[startDate, endDate]}
      yDomain={[
        0,
        Math.max(Math.max(...indexPriceHistory), Math.max(...investmentWorth)),
      ]}
      i18nStrings={{
        xTickFormatter: e =>
          e
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
            })
            .split(',')
            .join('\n'),
        yTickFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      }}
      ariaLabel="Single data series line chart"
      height={300}
      hideFilter
      xScaleType="time"
      xTitle="Time"
      yTitle="$"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default InvestmentWorthChart;

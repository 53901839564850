import Header from './Header';
import FinancialLiteracyTestQuestions from './FinancialLiteracyTestQuestions';

const FinancialLiteracyTestPage = () => {
  return (
    <article className="prose prose-slate prose-xl max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-20">
      <Header />
      <FinancialLiteracyTestQuestions />
    </article>
  );
};

export default FinancialLiteracyTestPage;

import LineChart from '@cloudscape-design/components/line-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'US property value growth rate',
    type: 'line',
    data: [
      { x: new Date(2011, 0), y: -1.83 },
      { x: new Date(2012, 0), y: 9.07 },
      { x: new Date(2013, 0), y: 9.27 },
      { x: new Date(2014, 0), y: 8.3 },
      { x: new Date(2015, 0), y: 4.28 },
      { x: new Date(2016, 0), y: 0.08 },
      { x: new Date(2017, 0), y: 6.65 },
      { x: new Date(2018, 0), y: 0.03 },
      { x: new Date(2019, 0), y: -0.29 },
      { x: new Date(2020, 0), y: 2.08 },
      { x: new Date(2021, 0), y: 18.45 },
      { x: new Date(2022, 0), y: 16.33 },
    ],
    valueFormatter: e => `${e}%`,
  },
  {
    title: 'Inflation rate',
    type: 'line',
    data: [
      { x: new Date(2011, 0), y: 3 },
      { x: new Date(2012, 0), y: 1.7 },
      { x: new Date(2013, 0), y: 1.5 },
      { x: new Date(2014, 0), y: 0.8 },
      { x: new Date(2015, 0), y: 0.7 },
      { x: new Date(2016, 0), y: 2.1 },
      { x: new Date(2017, 0), y: 2.1 },
      { x: new Date(2018, 0), y: 1.9 },
      { x: new Date(2019, 0), y: 2.3 },
      { x: new Date(2020, 0), y: 1.4 },
      { x: new Date(2021, 0), y: 7 },
      { x: new Date(2022, 0), y: 8.2 },
    ],
    valueFormatter: e => `${e}%`,
  },
];

const PropertyValueVersusInflationGraph = () => {
  return (
    <>
      <LineChart
        series={CHART_DATA}
        xDomain={[new Date(2011, 0), new Date(2022, 1)]}
        yDomain={[-5, 20]}
        i18nStrings={{
          xTickFormatter: e =>
            e.toLocaleDateString('en-US', {
              year: 'numeric',
            }),
          yTickFormatter: function o(e) {
            return `${e}%`;
          },
        }}
        height={300}
        hideFilter
        xScaleType="time"
        xTitle="Year"
        yTitle="Percentage"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          US property value growth rate versus inflation rate
        </Typography>
      </figcaption>
    </>
  );
};

export default PropertyValueVersusInflationGraph;

import { Link } from 'react-router-dom';

export const ItemRows = ({ items }) => {
  return (
    <div>
      {items.map((item, i) => (
        <article
          key={item.id}
          className={`grid grid-cols-1 lg:grid-cols-10 gap-5 mb-9 ${
            i !== 0 && 'border-t pt-9'
          }`}
        >
          <Link
            to={`/blogs/${item.slug}`}
            className="block lg:col-span-3 my-auto mx-auto"
          >
            <img
              className="aspect-[16/9] w-full max-w-md"
              src={item.image}
              alt={item.title}
            />
          </Link>
          <div className="lg:col-span-7 lg:ml-8">
            <Link to={`/blogs/${item.slug}`} className="block">
              <h3 className="text-2xl font-semibold mb-2 hover:text-primary-700">
                {item.title}
              </h3>
            </Link>
            <p className="text-sm text-slate-600 mb-2">By {item.author}</p>
            <div className="mb-5 flex flex-wrap">
              {item.categories.map(category => (
                <span
                  key={category.id}
                  className="text-xs leading-none text-center whitespace-nowrap font-bold bg-gray-200 rounded-full py-1 px-2.5 mr-2 mt-1"
                >
                  {category.name}
                </span>
              ))}
            </div>
            <p className="text-base text-black leading-6 mb-6">
              {item.summary}
            </p>
            <button className="w-full lg:w-fit rounded-md shadow">
              <Link
                to={`/blogs/${item.slug}`}
                className="block text-base text-white font-medium rounded-md bg-primary-600 hover:bg-primary-700 md:text-lg px-12 py-2"
              >
                Read Now!
              </Link>
            </button>
          </div>
        </article>
      ))}
    </div>
  );
};

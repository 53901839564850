import * as React from 'react';
import BarChart from '@cloudscape-design/components/bar-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Starting',
    type: 'bar',
    data: [{ x: 'Credit Score', y: 300 }],
    color: '#991B1B',
    valueFormatter: () => '0 - 300',
  },
  {
    title: 'Poor',
    type: 'bar',
    data: [{ x: 'Credit Score', y: 279 }],
    color: '#F97316',
    valueFormatter: () => '301 - 579',
  },
  {
    title: 'Fair',
    type: 'bar',
    data: [{ x: 'Credit Score', y: 160 }],
    color: '#FBBF24',
    valueFormatter: () => '580 - 739',
  },
  {
    title: 'Good',
    type: 'bar',
    data: [{ x: 'Credit Score', y: 60 }],
    color: '#8BD06C',
    valueFormatter: () => '740 - 799',
  },
  {
    title: 'Excellent',
    type: 'bar',
    data: [{ x: 'Credit Score', y: 51 }],
    color: '#10B981',
    valueFormatter: () => '800 - 850',
  },
];

const CreditScoreRangeGraph = () => {
  return (
    <>
      <BarChart
        series={CHART_DATA}
        xDomain={['Credit Score']}
        yDomain={[0, 851]}
        height={150}
        hideFilter
        horizontalBars
        stackedBars
      />

      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Credit score ranges
        </Typography>
      </figcaption>
    </>
  );
};

export default CreditScoreRangeGraph;

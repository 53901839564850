import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, idx) => idx + start);
};

const calculateRange = (currentPage, itemCount, pageSize, siblingCount) => {
  const totalPageCount = Math.ceil(itemCount / pageSize);

  // siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageShown = siblingCount * 2 + 5;

  // Case 1: number of pages less than the page to be shown
  if (totalPageCount <= totalPageShown) {
    return range(1, totalPageCount);
  }

  const leftmostSiblingIdx = Math.max(currentPage - siblingCount, 1);
  const rightmostSiblingIdx = Math.min(
    currentPage + siblingCount,
    totalPageCount
  );

  // only show dots when there are two or more pages to hide
  const shouldShowLeftDots = leftmostSiblingIdx >= 4;
  const shouldShowRightDots = rightmostSiblingIdx <= totalPageCount - 3;

  //Case 2: No left dots to show, but rights dots to be shown
  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = totalPageShown - 2;
    let leftRange = range(1, leftItemCount);
    return [...leftRange, -1, totalPageCount];
  }

  //Case 3: No right dots to show, but left dots to be shown
  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = totalPageShown - 2;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
    return [1, -1, ...rightRange];
  }

  //Case 4: Both left and right dots to be shown
  let middleRange = range(leftmostSiblingIdx, rightmostSiblingIdx);
  return [1, -1, ...middleRange, -2, totalPageCount];
};

const Pagination = ({
  setCurrentPage,
  currentPage,
  itemCount,
  pageSize,
  siblingCount = 1,
}) => {
  const paginationRange = calculateRange(
    currentPage,
    itemCount,
    pageSize,
    siblingCount
  );

  if (currentPage < 0 || paginationRange.length < 2) return null;

  const firstItemIdx = (currentPage - 1) * pageSize;
  const lastItemIdx = Math.min(firstItemIdx + pageSize - 1, itemCount - 1);
  const allowPrevious = currentPage > 1;
  const allowNext = currentPage < paginationRange[paginationRange.length - 1];

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${
            allowPrevious ? 'hover:bg-gray-50' : ''
          }`}
          disabled={allowPrevious ? false : true}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white ${
            allowNext ? 'hover:bg-gray-50' : ''
          }`}
          disabled={allowNext ? false : true}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing
            <span className="font-medium"> {firstItemIdx + 1} </span>
            to
            <span className="font-medium"> {lastItemIdx + 1} </span>
            of
            <span className="font-medium"> {itemCount} </span>
            results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className={`relative inline-flex items-center p-2 rounded-l-md border border-gray-300 bg-white ${
                allowPrevious
                  ? 'text-gray-500 hover:bg-gray-50'
                  : 'text-gray-300'
              }`}
              disabled={allowPrevious ? false : true}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {paginationRange.map(pageNum => (
              <button
                key={pageNum}
                onClick={pageNum < 0 ? null : () => setCurrentPage(pageNum)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                  pageNum === currentPage
                    ? 'z-10 bg-primary-50 border-primary-500 text-primary-700'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                }`}
                disabled={pageNum < 0 ? true : false}
              >
                {pageNum < 0 ? '...' : pageNum}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className={`relative inline-flex items-center p-2 rounded-r-md border border-gray-300 bg-white ${
                allowNext ? 'text-gray-500 hover:bg-gray-50' : 'text-gray-300'
              }`}
              disabled={allowNext ? false : true}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

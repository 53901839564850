import * as d3 from 'd3';
import { addAxis } from '../utils/axes';

const graph4 = () => {
  const aspectRatio = 1.5;
  const width = 739;
  const height = width / aspectRatio;
  const margin = { top: 10, right: 48, bottom: 38, left: 45 };

  const interestRates = [14.52, 15.35, 16.46, 17.14, 16.03, 16.26];
  const interestRatesExtent = [12, 18];
  const years = Array.from({ length: 6 }, (_, i) => 2016 + i);

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(years))
    .range([margin.left, width - margin.right]);

  const yScale = d3
    .scaleLinear()
    .domain(interestRatesExtent)
    .range([height - margin.bottom, margin.top]);

  const data = interestRates.map((d, i) => {
    return {
      interestRate: d,
      year: years[i],
    };
  });

  const graphContainer = d3
    .select('#graph4')
    .text('')
    .style('overflow', 'auto');
  const graph = graphContainer
    .append('svg')
    .attr('height', height)
    .attr('width', width);
  graphContainer
    .append('figcaption')
    .classed('text-center text-sm', true)
    .html(
      'Figure 4: Average credit card interest rates from 2016 to 2021, source: <a href="https://www.creditcards.com" target="_blank" rel="noreferrer noopener">creditcards.com</a>'
    );

  // line chart
  graph
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'steelblue')
    .attr('stroke-width', 1.5)
    .attr(
      'd',
      d3
        .line()
        .x(d => xScale(d.year))
        .y(d => yScale(d.interestRate))
    );

  graph
    .selectAll('text')
    .data(data)
    .join('text')
    .text(d => `${d.interestRate}%`)
    .attr('x', d => xScale(d.year))
    .attr('y', d => yScale(d.interestRate) + 15)
    .attr('font-size', '14px')
    .attr('textLength', 48)
    .attr('lengthAdjust', 'spacingAndGlyphs');

  addAxis('left', graph, yScale, 'Average Credit Card Interest Rates (%)', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 2,
    },
    label: {
      textLength: 245,
      translateY: -35,
    },
  });

  addAxis('bottom', graph, xScale, 'Year', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 1,
      tickSize: 6,
      translateY: 15,
    },
    label: {
      textLength: 30,
      translateY: 36,
    },
  });
};

export default graph4;

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeaturedBlog from './FeaturedBlog';

const FeaturedBlogList = ({ exploreMoreClick }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    requestBlogs();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function requestBlogs() {
    const res = await fetch(
      `https://api.alphainvestinggroup.com/blogs/featured/`,
      {
        headers: { Authorization: process.env.REACT_APP_AIGAPIKEY },
      }
    );
    const json = await res.json();
    setBlogs(json);
  }

  return (
    <div className="relative pt-16 pb-20 lg:py-24">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Chart Your Path to Financial Freedom
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Empower your financial literacy with Alpha Investing Group’s to pave
            your way to financial freedom.
          </p>
        </div>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <Link
              to="/blogs"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10"
            >
              Read now
            </Link>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <button
              onClick={exploreMoreClick}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
            >
              Explore more
            </button>
          </div>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {blogs.map(blog => (
            <FeaturedBlog
              image={blog.image}
              title={blog.title}
              categories={blog.categories}
              summary={blog.summary}
              author={blog.author}
              date={blog.date}
              slug={blog.slug}
              key={blog.slug}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlogList;

import { forwardRef } from 'react';
import { Box, Card } from '@mui/material';
import Table from '@mui/material/Table';
import PaymentBreakdownChart from './PaymentBreakdownChart';
import AnnualBalancesChart from './AnnualBalancesChart';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Results = ({
  monthlyPayment,
  loanAmount,
  totalInterestPaid,
  armotizationTable,
}) => {
  return (
    <>
      {monthlyPayment && (
        <>
          <Box display="flex" flexWrap="wrap" mb={6}>
            <Card
              sx={{
                marginRight: '16px',
                borderRadius: '16px',
                padding: '24px 32px',
                boxShadow:
                  '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
            >
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="20px"
                color="#0F609B"
                mb={1}
              >
                Monthly Payment
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="18px"
                color="#374151"
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(monthlyPayment)}
              </Typography>
            </Card>
            <Card
              sx={{
                marginLeft: '16px',
                borderRadius: '16px',
                padding: '24px 32px',
                boxShadow:
                  '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
            >
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="20px"
                color="#0F609B"
                mb={1}
                width="200px"
              >
                Total paid:
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="18px"
                color="#374151"
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number(loanAmount) + totalInterestPaid)}
              </Typography>
            </Card>
          </Box>
          <Box display="flex" flexWrap="wrap" mb={6}>
            <Card
              sx={{
                marginRight: '16px',
                borderRadius: '16px',
                padding: '24px 32px',
                boxShadow:
                  '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
            >
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="20px"
                color="#0F609B"
                mb={1}
              >
                Total interest paid:
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="18px"
                color="#374151"
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(totalInterestPaid.toFixed(2))}
              </Typography>
            </Card>
            <Card
              sx={{
                marginLeft: '16px',
                borderRadius: '16px',
                padding: '24px 32px',
                boxShadow:
                  '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
                flexGrow: 1,
              }}
            >
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="20px"
                color="#0F609B"
                mb={1}
              >
                Mortgage Payoff date:
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily="Space Grotesk, sans-serif"
                fontSize="18px"
                color="#374151"
              >
                {new Date(
                  armotizationTable[armotizationTable.length - 1].paymentTime
                ).toDateString()}
              </Typography>
            </Card>
          </Box>
        </>
      )}
      <Box mt={5}>
        <Card
          sx={{
            padding: '20px',
            boxShadow:
              '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          <PaymentBreakdownChart
            data={[
              {
                title: 'Interest Paid',
                value: Number(totalInterestPaid?.toFixed(2)),
              },
              {
                title: 'Principal Paid',
                value: Number(Number(loanAmount)?.toFixed(2)),
              },
            ]}
          />
        </Card>
      </Box>
      <Box mt={5}>
        <Card
          sx={{
            padding: '20px',
            boxShadow:
              '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AnnualBalancesChart data={armotizationTable} />
        </Card>
      </Box>
      {armotizationTable.length > 1 && (
        <Box mt={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Balance</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell>Principal Amount</TableCell>
                  <TableCell>Interest Amount</TableCell>
                  <TableCell>Principal Paid</TableCell>
                  <TableCell>Interest Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {armotizationTable.map(schedule => (
                  <TableRow key={schedule.paymentNo}>
                    <TableCell>{schedule.balance.toFixed(2)}</TableCell>
                    <TableCell>{monthlyPayment.toFixed(2)}</TableCell>
                    <TableCell>{schedule.principalAmount.toFixed(2)}</TableCell>
                    <TableCell>{schedule.interestAmount.toFixed(2)}</TableCell>
                    <TableCell>{schedule.principalPaid.toFixed(2)}</TableCell>
                    <TableCell>{schedule.interestPaid.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

const ResultsWithRef = forwardRef(
  (
    { monthlyPayment, loanAmount, totalInterestPaid, armotizationTable },
    ref
  ) => (
    <div ref={ref}>
      <Results
        monthlyPayment={monthlyPayment}
        loanAmount={loanAmount}
        totalInterestPaid={totalInterestPaid}
        armotizationTable={armotizationTable}
      />
    </div>
  )
);

export default ResultsWithRef;

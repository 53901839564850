import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';

const FeaturedBlog = ({
  image,
  title,
  slug,
  categories,
  summary,
  author,
  date,
}) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden hover:scale-105">
      <Link to={`/blogs/${slug}`} className="block flex-shrink-0">
        <img className="h-48 w-full object-cover" src={image} alt={slug} />
      </Link>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-primary-600 flex flex-wrap">
            {categories.map(category => (
              <span className="hover:underline mr-3" key={category.id}>
                {category.name}
              </span>
            ))}
          </p>
          <Link to={`/blogs/${slug}`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            <p className="mt-3 text-base text-gray-500">{summary}</p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            {/* <a className="block" href="#"> */}
            <Avatar author={author} />
            {/* </a> */}
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              {/* <a href="#" className="hover:underline"> */}
              {author}
              {/* </a> */}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime="2020-03-16">
                {new Date(date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </time>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlog;

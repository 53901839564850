import axios from 'axios';
import { BLOG_LIST_URL, BLOG_CATEGORIES_URL } from './constants';

export const requestBlogCategories = async () => {
  try {
    const result = await axios.get(BLOG_CATEGORIES_URL, {
      headers: {
        Authorization: process.env.REACT_APP_AIGAPIKEY,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestBlogs = async category => {
  try {
    const queryParams = category === 'all' ? '' : `?category=${category}`;
    const result = await axios.get(`${BLOG_LIST_URL}${queryParams}`, {
      headers: {
        Authorization: process.env.REACT_APP_AIGAPIKEY,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const requestBlogDetails = async slug => {
  const url = `${BLOG_LIST_URL}${slug}/`;
  try {
    const result = await axios.get(url, {
      headers: {
        Authorization: process.env.REACT_APP_AIGAPIKEY,
      },
    });

    return result.data;
  } catch (error) {
    throw error;
  }
};

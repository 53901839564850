import LineChart from '@cloudscape-design/components/line-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'Average US property value',
    type: 'line',
    data: [
      { x: new Date(2010, 0), y: 272900 },
      { x: new Date(2011, 0), y: 267900 },
      { x: new Date(2012, 0), y: 292200 },
      { x: new Date(2013, 0), y: 319300 },
      { x: new Date(2014, 0), y: 345800 },
      { x: new Date(2015, 0), y: 360600 },
      { x: new Date(2016, 0), y: 360900 },
      { x: new Date(2017, 0), y: 384900 },
      { x: new Date(2018, 0), y: 385000 },
      { x: new Date(2019, 0), y: 383900 },
      { x: new Date(2020, 0), y: 391900 },
      { x: new Date(2021, 0), y: 464200 },
      { x: new Date(2022, 0), y: 540000 },
    ],
    valueFormatter: e =>
      `$${e.toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })}`,
  },
];

const PropertyValueGrowthGraph = () => {
  return (
    <>
      <LineChart
        series={CHART_DATA}
        xDomain={[new Date(2010, 0), new Date(2022, 1)]}
        yDomain={[250000, 550000]}
        i18nStrings={{
          xTickFormatter: e =>
            e.toLocaleDateString('en-US', {
              year: 'numeric',
            }),
          yTickFormatter: function o(e) {
            return `$${e.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}`;
          },
        }}
        height={300}
        hideFilter
        xScaleType="time"
        xTitle="Year"
        yTitle="Average US property value"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          Average US property value from 2016 to 2021
        </Typography>
      </figcaption>
    </>
  );
};

export default PropertyValueGrowthGraph;

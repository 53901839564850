import { useState } from 'react';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const LoanAmountForm = ({ loanAmount, setLoanAmount }) => {
  const [calculationMethod, setCalculationMethod] = useState('loanAmount');
  const [propertyPrice, setPropertyPrice] = useState('');
  const [downPayment, setDownPayment] = useState('');

  const handlePropertyPriceChange = (propertyPrice, downPayment) => {
    if (propertyPrice !== null && downPayment !== null) {
      setLoanAmount(propertyPrice - downPayment);
    }
  };

  return (
    <Card
      sx={{
        minWidth: '500px',
        marginBottom: '32px',
        borderRadius: '16px',
        boxShadow:
          '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box mt={6} mx={6}>
        <Box
          border="1px solid #6B7280"
          borderRadius="4px"
          display="flex"
          justifyContent="space-between"
          px={2}
          py={1}
          mb={3}
        >
          <Box
            px={2}
            py={1}
            width="202px"
            textAlign="center"
            borderRadius="4px"
            backgroundColor={
              calculationMethod === 'loanAmount' ? '#DCEEFB' : 'transparent'
            }
            sx={{
              '&:hover': {
                backgroundColor: '#eaf4fc',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setCalculationMethod('loanAmount');
              setLoanAmount('');
              setPropertyPrice('');
              setDownPayment('');
            }}
          >
            <Typography
              fontWeight={700}
              color={calculationMethod === 'loanAmount' ? '#0A558C' : '#374151'}
            >
              Loan amount
            </Typography>
          </Box>
          <Box
            px={2}
            py={1}
            width="202px"
            textAlign="center"
            borderRadius="4px"
            backgroundColor={
              calculationMethod === 'propertyPrice' ? '#DCEEFB' : 'transparent'
            }
            sx={{
              '&:hover': {
                backgroundColor: '#eaf4fc',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setCalculationMethod('propertyPrice');
              setLoanAmount('');
              setPropertyPrice('');
              setDownPayment('');
            }}
          >
            <Typography
              fontWeight={700}
              color={
                calculationMethod === 'propertyPrice' ? '#0A558C' : '#374151'
              }
            >
              Property Price
            </Typography>
          </Box>
        </Box>
        {calculationMethod === 'loanAmount' && (
          <Box mt={2}>
            <Typography
              component="p"
              fontWeight={700}
              fontSize="20px"
              fontFamily="inherit"
              mb={2}
            >
              How much do you want to loan?
            </Typography>
            <TextField
              value={loanAmount}
              onChange={e => setLoanAmount(Number.parseFloat(e.target.value))}
              hiddenLabel
              type="number"
              variant="outlined"
              fullWidth
              size="normal"
              placeholder="$500,000"
            />
          </Box>
        )}
        {calculationMethod === 'propertyPrice' && (
          <>
            <Box mt={2}>
              <Typography
                component="p"
                fontWeight={700}
                fontSize="20px"
                fontFamily="inherit"
                mb={2}
              >
                How much is the property going to cost?
              </Typography>
              <TextField
                value={propertyPrice}
                onChange={e => {
                  const updatedPropertyPrice = Number.parseFloat(
                    e.target.value
                  );
                  setPropertyPrice(updatedPropertyPrice);
                  handlePropertyPriceChange(updatedPropertyPrice, downPayment);
                }}
                hiddenLabel
                type="number"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="$500,000"
              />
            </Box>
            <Box mt={2}>
              <Typography
                component="p"
                fontWeight={700}
                fontSize="20px"
                fontFamily="inherit"
                mb={2}
              >
                How much is the down payment?
              </Typography>
              <TextField
                value={downPayment}
                onChange={e => {
                  const updatedDownPayment = Number.parseFloat(e.target.value);
                  setDownPayment(updatedDownPayment);
                  handlePropertyPriceChange(propertyPrice, updatedDownPayment);
                }}
                hiddenLabel
                type="number"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="$50,000"
              />
            </Box>
          </>
        )}
      </Box>
      <Box backgroundColor="#F3F4F6" textAlign="right" px={6} py={1.5} mt={6}>
        <Typography fontWeight={700} fontFamily="inherit">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(loanAmount || 0)}
        </Typography>
      </Box>
    </Card>
  );
};

export default LoanAmountForm;

export const BlogHeader = () => {
  return (
    <>
      <h1 className="text-5xl font-semibold mb-6">Blogs</h1>
      <div className="text-xl text-slate-700 leading-7">
        <p className="mb-4">
          At Alpha Investing Group, we aim to bring to you the most
          comprehensive, insightful investment content. We cover various topics,
          such as stock valuation, financial literacy, personal finance,
          cryptocurrency, and many more. What are you waiting for? Explore our
          blogs and start your investing journey today.
        </p>
        <p className="mb-4">
          Please feel free to leave us a comment or send an email to{' '}
          <a
            href="mailto:alphainvestinggroup.general@gmail.com"
            className="text-primary-600 hover:text-primary-700 hover:underline"
          >
            alphainvestinggroup.general@gmail.com
          </a>{' '}
          if there are some specific topics that you would like us to write
          about.
        </p>
      </div>
    </>
  );
};

const CurrencyItem = ({ img, name, id, isQueryResult, onClick, className }) => {
  return (
    <div
      className={`flex flex-row h-full w-full px-4 ${className}`}
      onClick={onClick}
    >
      <div className="self-center flex-none">
        <img className="w-8 h-8" src={img} alt={name} />
      </div>
      <div className="self-center pl-4 max-w-[88%]">
        <p className={`text-lg font-bold ${isQueryResult || 'truncate'}`}>
          {name}
        </p>
        <p className={`text-sm text-gray-600 ${isQueryResult || 'truncate'}`}>
          {id}
        </p>
      </div>
    </div>
  );
};

export default CurrencyItem;

import { useState, useEffect } from 'react';
import { requestCoinValue } from './utils';
import convert from './convert';
import { INITIAL_BASE_CURRENCY, INITIAL_TARGET_CURRENCY } from './constants';

const useConvert = ({ error, setError }) => {
  const [baseCurrency, setBaseCurrency] = useState(INITIAL_BASE_CURRENCY);
  const [targetCurrency, setTargetCurrency] = useState(INITIAL_TARGET_CURRENCY);

  useEffect(() => {
    if (error) {
      setBaseCurrency(c => INITIAL_BASE_CURRENCY);
      setTargetCurrency(c => INITIAL_TARGET_CURRENCY);
    }
  }, [error]);

  useEffect(() => {
    async function init() {
      try {
        const baseAmount = baseCurrency.amount;
        const baseUSDValue = await requestCoinValue(baseCurrency.id);
        const targetUSDValue = await requestCoinValue(targetCurrency.id);
        setBaseCurrency({ ...baseCurrency, usdValue: baseUSDValue });
        setTargetCurrency({
          ...targetCurrency,
          usdValue: targetUSDValue,
          amount: convert(baseAmount, baseUSDValue, targetUSDValue),
        });
        setError(null);
      } catch (e) {
        setError(e);
      }
    }
    init();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function handleCoinUpdate(newCoinID, currencyType) {
    try {
      const baseAmount = baseCurrency.amount;
      const usdValue = await requestCoinValue(newCoinID);

      if (currencyType === 'base') {
        const targetUSDValue = targetCurrency.usdValue;
        setBaseCurrency(c => ({ ...c, usdValue: usdValue }));
        setTargetCurrency(c => ({
          ...c,
          amount: convert(baseAmount, usdValue, targetUSDValue),
        }));
      } else {
        let baseUSDValue = baseCurrency.usdValue;
        if (!baseUSDValue) {
          baseUSDValue = await requestCoinValue(baseCurrency.id);
        }

        setTargetCurrency(c => ({
          ...c,
          usdValue: usdValue,
          amount: convert(baseAmount, baseUSDValue, usdValue),
        }));
      }
      setError(null);
    } catch (e) {
      setError(e);
    }
  }

  function handleAmountUpdate(amount, currencyType) {
    try {
      if (currencyType === 'base') {
        const targetUSDValue = targetCurrency.usdValue;
        setTargetCurrency(c => ({
          ...c,
          amount: convert(amount, baseCurrency.usdValue, targetUSDValue),
        }));
      } else {
        const baseUSDValue = baseCurrency.usdValue;
        setBaseCurrency(c => ({
          ...c,
          amount: convert(amount, targetCurrency.usdValue, baseUSDValue),
        }));
      }
      setError(null);
    } catch (e) {
      setError(e);
    }
  }

  const handleRevert = () => {
    const baseUSDValue = baseCurrency.usdValue;
    const targetUSDValue = targetCurrency.usdValue;

    const temp = { ...baseCurrency };
    setBaseCurrency({ ...targetCurrency, amount: 1 });
    setTargetCurrency({
      ...temp,
      amount: convert(1, targetUSDValue, baseUSDValue),
    });
  };

  return [
    [baseCurrency, setBaseCurrency],
    [targetCurrency, setTargetCurrency],
    handleCoinUpdate,
    handleAmountUpdate,
    handleRevert,
  ];
};

export default useConvert;

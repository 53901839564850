import FeaturedBlogList from './FeaturedBlogList';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return <FeaturedBlogList exploreMoreClick={() => navigate('/tools')} />;
};

export default HomePage;

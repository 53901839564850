import { subMonths, addMonths } from 'date-fns';

export const calculateMonthlyPayment = (
  loanAmount,
  loanTerm,
  monthlyInterest
) => {
  return (
    (loanAmount *
      Math.pow(1 + monthlyInterest, loanTerm * 12) *
      monthlyInterest) /
    (Math.pow(1 + monthlyInterest, loanTerm * 12) - 1)
  );
};

export const calculateArmotization = (
  loanAmount,
  monthlyPayment,
  monthlyInterest,
  firstPaymentTime
) => {
  const armotization = [];
  let balance = loanAmount;
  let paymentNo = 0;
  let paymentTime = subMonths(firstPaymentTime, 1).getTime();

  let totalInterestPaid = 0;
  let interestPaid = 0;
  let principalPaid = 0;

  while (balance > 0) {
    paymentNo += 1;
    paymentTime = addMonths(paymentTime, 1).getTime();
    const interestAmount = balance * monthlyInterest;
    const principalAmount = monthlyPayment - interestAmount;

    interestPaid += interestAmount;
    principalPaid += principalAmount;

    armotization.push({
      paymentNo,
      paymentTime,
      balance,
      interestPaid,
      principalPaid,
      principalAmount,
      interestAmount,
    });
    totalInterestPaid += interestAmount;
    balance = balance - principalAmount;
  }

  return { armotization, totalInterestPaid };
};

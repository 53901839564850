import { Link } from 'react-router-dom';

const TermsOfUse = () => {
  return (
    <article className="prose prose-slate prose-xl break-words mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
      <h1>Terms of Use</h1>
      <h2>Introduction</h2>
      <p>
        Welcome to Alpha Investing Group. These terms of service outline the
        rules and guidelines for using our website. By using our website, you
        agree to the terms of this agreement, as well as our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>

      <h2>User Conduct</h2>
      <p>
        You agree not to use our website for any unlawful or prohibited
        activities, such as hacking, spamming, or distributing malware. You also
        agree not to post any defamatory, discriminatory, or obscene content on
        our website.
      </p>
      <p>
        If you want to republish or reprint an article from this site, please
        send us a request to{' '}
        <a href="mailto:alphainvestinggroup.general@gmail.com">
          alphainvestinggroup.general@gmail.com
        </a>
      </p>

      <h2>Investment Disclaimer</h2>
      <p>
        The content on this website is provided for informational purposes only
        and should not be construed as investment advice. The information on
        this website does not take into account your personal circumstances or
        financial goals, and should not be relied upon for making investment
        decisions.
      </p>
      <p>
        We are not financial advisors and do not provide investment
        recommendations or advice. You are solely responsible for conducting
        your own research and due diligence before making any investment
        decisions. We are not responsible for any investment decisions you make
        based on the information on this website.
      </p>
      <p>
        You should always seek the advice of a qualified financial professional
        before making any investment decisions. We are not liable for any losses
        or damages that may result from your reliance on the information on this
        website.
      </p>

      <h2>User Accounts</h2>
      <p>
        If you create an account on our website, you are responsible for
        maintaining the confidentiality of your account and password and for
        restricting access to your computer or mobile device. You agree to
        accept responsibility for all activities that occur under your account
        or password. We reserve the right to terminate your account at any time
        if you violate these terms of service.
      </p>

      <h2>Embedded Content</h2>
      <p>
        Our website may include embedded content (e.g. videos, images, articles)
        from third-party websites such as YouTube or Vimeo. Embedded content
        from other websites behaves in the same way as if the visitor has
        visited the other website.
      </p>
      <p>
        These third-party websites may collect data about you, use cookies,
        embed additional third-party tracking, and monitor your interaction with
        the embedded content, including tracking your interaction with the
        embedded content if you have an account and are logged in to that
        website.
      </p>
      <p>
        We do not control the information collected by these third-party
        websites and are not responsible for their privacy practices. We
        encourage you to review the privacy policies of these third-party
        websites to understand how they collect and use your information.
      </p>

      <h2>Third-party Services</h2>
      <p>
        We use third-party services such as Google Analytics, Google AdSense,
        and Amazon Associate on our website. These services may collect and use
        information about your browsing behavior and device to provide us with
        analytics and targeted advertising.
      </p>
      <p>
        By using our website, you agree to the terms and privacy policies of
        these third-party services. You can opt-out of Google Analytics and
        Google AdSense by installing the Google Analytics opt-out browser add-on
        or adjusting your ad settings. You can also opt-out of Amazon Associate
        by disabling cookies in your browser settings.
      </p>

      <h2>External Links</h2>
      <p>
        Links to third-party websites or resources may be provided on our
        website. You acknowledge and agree that we are not responsible or liable
        for: (i) the availability or accuracy of such websites or resources; or
        (ii) the content, products, or services on or available from such
        websites or resources. Links to such websites or resources do not imply
        any endorsement by Alpha Investing Group of such websites or resources
        or the content, products, or services available from such websites or
        resources.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        All content on our website, including text, images, and videos, is owned
        by Alpha Investing Group and is protected by copyright laws. You may not
        use or reproduce our content without our written permission.
      </p>

      <h2>Disclaimer of Warranties</h2>
      <p>
        We make no warranty or representation that our website will meet your
        requirements, or that your use of our website will be uninterrupted,
        timely, secure, or error-free. We are not liable for any damages or
        losses that may arise from the use of our website, including but not
        limited to lost profits or data, or damage to your device.
      </p>

      <h2>Limitations of Liability</h2>
      <p>
        In no event shall Alpha Investing Group be liable for any indirect,
        incidental, special, punitive, or consequential damages arising out of
        or in connection with your use of our website. We are also not liable
        for any third-party content or websites that may be linked to from our
        website.
      </p>

      <h2>Dispute Resolution</h2>
      <p>
        Any disputes arising from the use of our website shall be resolved
        through binding arbitration in accordance with the rules of the American
        Arbitration Association. The arbitration shall take place in the United
        States and shall be conducted in English. The arbitrator's award shall
        be binding and may be entered as a judgment in any court of competent
        jurisdiction.
      </p>

      <h2>Termination</h2>
      <p>
        We may terminate your access to our website at any time if you violate
        these terms of service. You may also terminate this agreement by ceasing
        to use our website.
      </p>

      <h2>Privacy</h2>
      <p>
        Your privacy is important to us. We collect and use personal information
        in accordance with our Privacy Policy, which you can find on our
        website. By using our website, you agree to our collection, use, and
        disclosure of your personal information as described in our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>

      <h2>Complete Agreement</h2>
      <p>
        These Terms of Use, along with our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>, constitute the entire
        agreement between you and our website with respect to your use of our
        website and services, and supersede all prior or contemporaneous
        agreements or understandings, whether written or oral, between you and
        our website. Any ambiguities in the interpretation of these Terms of Use
        shall not be construed against the drafting party.
      </p>

      <h2>Contact Us</h2>
      <p>
        Should you have any questions about our terms of use, feel free to send
        an email to{' '}
        <a href="mailto:alphainvestinggroup.general@gmail.com">
          alphainvestinggroup.general@gmail.com
        </a>
        .
      </p>
      <p>You can also contact us by mail via this mailing address:</p>
      <p>
        Alpha Investing Group
        <br />
        Flat 607, Hennessey Apartments,
        <br />
        5 Brigadier Walk,
        <br />
        SE18 6YT London,
        <br />
        United Kingdom.
      </p>
    </article>
  );
};

export default TermsOfUse;

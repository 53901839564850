import { useState, useEffect } from 'react';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import Currency from './Currency';
import Header from './Header';
import { requestTop10Coins } from './utils';
import useConvert from './useConvert';

const CryptoConverter = () => {
  const [top10Coins, setTop10Coins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [
    baseCurrency,
    targetCurrency,
    handleCoinUpdate,
    handleAmountUpdate,
    handleRevert,
  ] = useConvert({ error, setError });

  useEffect(() => {
    async function getTop10Coins() {
      try {
        const top10Coins = await requestTop10Coins();
        setTop10Coins(top10Coins);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    }
    getTop10Coins();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null;

  return (
    <>
      <article className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
        <Header />

        <div className="mt-10">
          {error && (
            <div className="bg-red-100 py-3 px-5 rounded-lg">
              <p className="text-red-900">{error?.message || ''}</p>
            </div>
          )}
          <article className="flex flex-col justify-center bg-gray-100 rounded-lg py-10 px-5 sm:px-10 mt-6 lg:flex-row">
            <section className="flex-auto">
              <h2 className="text-xl font-bold">From</h2>
              <Currency
                top10Coins={top10Coins}
                currency={baseCurrency}
                handleCoinUpdate={handleCoinUpdate}
                handleAmountUpdate={handleAmountUpdate}
                currencyType="base"
                setError={setError}
              />
            </section>

            <section className="flex-none self-center mx-14 mt-5 mb-2 lg:my-0">
              <button
                className="bg-primary-600 rounded-md w-14 h-10 my-auto hover:bg-primary-700"
                onClick={handleRevert}
              >
                <ArrowsRightLeftIcon className="text-white w-6 h-6 mx-auto" />
              </button>
            </section>

            <section className="flex-auto">
              <h2 className="text-xl font-bold">To</h2>
              <Currency
                top10Coins={top10Coins}
                currency={targetCurrency}
                handleCoinUpdate={handleCoinUpdate}
                handleAmountUpdate={handleAmountUpdate}
                currencyType="target"
                setError={setError}
              />
            </section>
          </article>
          <div className="flex justify-end mt-2 text-[17px]">
            <p>
              Powered by <span>CoinGecko</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default CryptoConverter;

import * as d3 from 'd3';
import { addAxis } from '../utils/axes';
import { addToolTip } from '../utils/tooltip';

const graph2 = () => {
  const aspectRatio = 1.5;
  const width = 710;
  const height = width / aspectRatio;
  const margin = { top: 18, right: 0, bottom: 35, left: 78 };

  const investmentValues = [
    4442.84, 8991.87, 14017.25, 19568.85, 25701.77, 32476.9, 39961.46, 48229.76,
    57363.85, 67454.41, 78601.57, 90916.0, 104519.9, 119548.31, 136150.39,
    154490.92, 174751.95, 197134.57, 221860.95, 249176.5, 279352.34, 312687.99,
    349514.32, 390196.84, 435139.36, 484787.95, 539635.39, 600226.08, 667161.4,
    741105.73, 822792.99, 913033.98, 1012724.38,
  ];
  const highestAxisInvestmentValues = 1200000;
  const years = Array.from({ length: 33 }, (_, i) => i + 18);

  const xScale = d3
    .scaleBand()
    .domain(years)
    .range([margin.left, width - margin.right])
    .padding(0.2);
  const yScale = d3
    .scaleLinear()
    .domain([0, highestAxisInvestmentValues])
    .range([height - margin.bottom, margin.top]);

  const data = investmentValues.map((d, i) => {
    return {
      investmentValue: d,
      year: years[i],
      x: xScale(years[i]),
      y: yScale(d),
      height: height - margin.bottom - yScale(d),
    };
  });

  const graphContainer = d3
    .select('#graph2')
    .text('')
    .style('overflow', 'auto');
  const graph = graphContainer
    .append('svg')
    .attr('height', height)
    .attr('width', width);
  graphContainer
    .append('figcaption')
    .classed('text-center text-sm', true)
    .html(
      'Figure 2: Investment returns from age of 18 to 50, assuming 10% annual return'
    );

  // bar chart
  graph
    .selectAll('rect')
    .data(data)
    .join(enter => {
      const rect = enter
        .append('rect')
        .attr('x', d => d.x)
        .attr('y', _ => height - margin.bottom)
        .attr('height', _ => 0)
        .attr('width', xScale.bandwidth())
        .attr('fill', '#186FAF');
      return rect;
    })
    .transition()
    .attr('y', d => d.y)
    .attr('height', d => d.height);

  const dataLabel1 = {
    ...data[0],
    labelLength: 50,
    labelHeight: 16.5,
    translateY: -3,
    translateX: 40,
  };
  const dataLabel2 = {
    ...data[data.length - 1],
    labelLength: 75,
    labelHeight: 16.5,
    translateY: -18,
    translateX: 50,
  };
  // data label
  graph
    .selectAll('text')
    .data([dataLabel1, dataLabel2])
    .join('text')
    .text(d => `$${Math.round(d.investmentValue).toLocaleString()}`)
    .attr('x', d => d.x + d.translateX)
    .attr('y', d => d.y + d.translateY)
    .attr(
      'transform',
      d => `rotate(270 ${d.x + d.labelLength / 2} ${d.y + d.labelHeight / 2})`
    )
    .attr('font-size', '14px')
    .attr('textLength', d => d.labelLength)
    .attr('lengthAdjust', 'spacingAndGlyphs');

  addToolTip(
    graphContainer,
    graph,
    'rect',
    Math.ceil(years.length / 2),
    (tooltip, d) => {
      tooltip.html(
        `<strong>Age:</strong> ${
          d.year
        }<br /><strong>Investment Value:</strong> $${Math.round(
          d.investmentValue
        ).toLocaleString()}`
      );
    },
    { width: 240 }
  );

  addAxis('left', graph, yScale, 'Investment Value ($)', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 200000,
    },
    label: {
      textLength: 130,
      translateY: -67,
    },
  });

  addAxis('bottom', graph, xScale, 'Age', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 1,
      tickSize: 0,
      translateY: 12,
    },
    label: {
      textLength: 25,
      translateY: 28,
    },
  });
};

export default graph2;

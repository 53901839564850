import {
  RewardParticipationPercentageGraph,
  CreditCardOwnershipPercentageGraph,
  CreditCardBalanceGraph,
  CreditCardInterestGraph,
} from './CollegeCreditCardGraphs';

import {
  PropertyValueGrowthGraph,
  PropertyValueVersusInflationGraph,
  PropertyValueVersusOtherInvestmentGraph,
} from './RealEstateInvestingGraphs';

import {
  CreditScoreInfluencingFactorsGraph,
  CreditScoreRangeGraph,
} from './CreditScoreGraphs';

export const GRAPH_MAP = {
  rewardParticipationPercentageGraph: <RewardParticipationPercentageGraph />,
  creditCardOwnershipPercentageGraph: <CreditCardOwnershipPercentageGraph />,
  creditCardBalanceGraph: <CreditCardBalanceGraph />,
  creditCardInterestGraph: <CreditCardInterestGraph />,
  propertyValueGrowthGraph: <PropertyValueGrowthGraph />,
  propertyGrowthVSInflationGraph: <PropertyValueVersusInflationGraph />,
  propertyVSOtherInvestmentGraph: <PropertyValueVersusOtherInvestmentGraph />,
  creditScoreInfluencingFactorsGraph: <CreditScoreInfluencingFactorsGraph />,
  creditScoreRangesGraph: <CreditScoreRangeGraph />,
};

import { useEffect, useState } from 'react';
import { requestBlogCategories, requestBlogs } from './utils';
import { Helmet } from 'react-helmet-async';
import { BlogHeader } from './BlogHeader';
import { ItemRows } from '../../components/ItemRows';
import Pagination from '../../components/Pagination';
import CategoryDropdown from '../../components/dropdowns/CategoryDropdown';

const pageSize = 10;
const categoryAll = { id: 0, name: 'All', slug: 'all' };

const BlogListPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(categoryAll);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBlogCategories = async () => {
      try {
        const allBlogCategories = await requestBlogCategories();
        setCategories([categoryAll, ...allBlogCategories]);
      } catch (error) {}
    };
    getBlogCategories();
  }, []);

  useEffect(() => {
    const getBlogs = async () => {
      setLoading(true);
      try {
        const allBlogs = await requestBlogs(category.slug);
        setBlogs(allBlogs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };
    getBlogs();

    return () => setLoading(false);
  }, [category]);

  // get books in current page
  const firstItemIdx = (currentPage - 1) * pageSize;
  const lastItemIdx = firstItemIdx + pageSize;
  const currentBlogs = blogs.slice(firstItemIdx, lastItemIdx);

  return (
    <>
      <Helmet>
        <title>
          Best Investing Blogs for Investors | Alpha Investing Group
        </title>
        <meta
          name="description"
          content="Discover the complete investment reading list and find the top stock investing books to read for beginners. Check out the best investing books now!"
        />
      </Helmet>
      <article className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-20">
        <BlogHeader />
        <CategoryDropdown
          currentItem={category}
          items={categories}
          clickHandler={category => {
            setCategory(category);
            setCurrentPage(1);
          }}
        />
        {blogs.length && !loading ? (
          <ItemRows items={currentBlogs} />
        ) : (
          <p>Loading</p>
        )}
        <Pagination
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={blogs.length}
          pageSize={pageSize}
        />
      </article>
    </>
  );
};

export default BlogListPage;

import { useState } from 'react';

import InvestmentDetailsForm from './InvestmentDetailsForm';
import Results from './Results';
import { calculateResults } from './helpers';
import { useForm, FormProvider } from 'react-hook-form';

const IndexInvestingCalculator = () => {
  const form = useForm({
    defaultValues: {
      investAmount: undefined,
      monthlyInvestment: undefined,
      startDate: new Date(1985, 0, 1, 12),
      endDate: new Date(2023, 9, 1, 12),
      index: undefined,
    },
  });

  const [results, setResults] = useState(null);

  const onSubmit = async values => {
    const results = await calculateResults(values);
    setResults(results);
  };

  return (
    <article className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
      <header>
        <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
          Tools
        </span>
        <h1 className="text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 mt-2">
          Index Investing Calculator
        </h1>
      </header>
      <p className="whitespace-pre-line text-xl text-slate-700 leading-7 mt-8">
        Ready to turn market waves into your financial gain? Dive into the savvy
        world of index investing where you can ride alongside the giants of the
        stock market. Our Index Investing Calculator is your compass in the vast
        investment ocean, empowering you with insights to navigate towards your
        wealth goals. Whether you're a budding investor or a seasoned finance
        aficionado, this tool is designed to demystify the market's complexities
        and crystalize your investment acumen. Join us as we chart a course to
        financial growth and unveil how embracing index investing can be your
        strategy for success.
      </p>

      <div className="mt-14 lg:mx-auto lg:w-full lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-20">
        <div className="lg:col-start-1">
          <article className="w-full">
            <FormProvider {...form}>
              <InvestmentDetailsForm onSubmit={onSubmit} />
              <Results results={results} />
            </FormProvider>
          </article>
        </div>

        <div className="mt-12 sm:mt-16 lg:-mt-6 lg:col-start-2">
          <article className="prose prose-slate prose-lg prose-li:my-1 prose-h2:my-6 prose-a:no-underline prose-a:text-primary-600 hover:prose-a:text-primary-800 hover:prose-a:underline mx-auto">
            <h2>Understanding the Fundamentals of Index Investing</h2>
            <p>
              At its core, index investing is a strategy that aims to mirror the
              performance of a specific financial market index, such as the FTSE
              100 or S&P 500. Rather than picking individual stocks, index
              investors buy into a fund that replicates an index, thereby
              diversifying their portfolio across all the businesses within it.
              This approach is celebrated for several reasons:
            </p>
            <ul>
              <li>
                <strong>Diversification</strong>: Index funds spread out your
                risk by investing in a wide array of companies. If one stock
                takes a dive, the performance of the others can help balance
                your portfolio.
              </li>
              <li>
                <strong>Cost-effectiveness</strong>: With lower fund management
                fees, index investing is a more cost-efficient way to invest in
                a broad market segment.
              </li>
              <li>
                <strong>Simplicity</strong>: It removes the complexity of stock
                selection and market timing, making it a favoured approach for
                both novice and seasoned investors.
              </li>
              <li>
                <strong>Performance</strong>: Over time, index funds have often
                outperformed actively managed funds, making them an attractive
                option for long-term growth.
              </li>
            </ul>
            <p>
              By opting for index investing, you align your investment success
              with the collective progress of the market's companies. It's a
              strategy that champions the idea of 'rising with the tide,' where
              the general uplift of the economy works in your favour.
            </p>

            <h2>How to use the index investing calculator?</h2>
            <p>
              Now, let’s use the index investing calculator to project the
              growth of an investment. Consider the following illustrative
              scenario:
            </p>
            <ul>
              <li>Initial investment: £10,000</li>
              <li>Monthly investment: £500</li>
              <li>Investment period: January 2000 to December 2020</li>
              <li>Chosen index: Nasdaq</li>
            </ul>
            <p>Employ the Index Investing Calculator with these steps:</p>
            <ol>
              <li>
                <p>
                  <strong>
                    Determine you initial investment and monthly investment
                  </strong>
                  <br />
                  Input the initial sum you are starting with. Here, we have
                  £10,000 as the starting point. Then, decide on your recurring
                  investment, in this case, £500 each month. These figures
                  establish the basis of your investment growth.
                </p>
              </li>
              <li>
                <p>
                  <strong>Identify your start date and end date</strong>
                  <br />
                  Select the period of your investment. For our scenario, the
                  start date is January 2000 and the end date is December 2020.
                  This time frame is crucial as it affects the compounding
                  effect on your investment.
                </p>
              </li>
              <li>
                <p>
                  <strong>Select an index to invest</strong>
                  <br />
                  Choose the market index that you wish to emulate with your
                  investment. By selecting the Nasdaq, your investment will be
                  aligned with the tech-heavy index, reflecting its performance.
                  You can also select the S&P 500 as the index for a more
                  diversified option.
                </p>
              </li>
              <li>
                <p className="mb-0">
                  <strong>Analyse the results of your investments</strong>
                  <br />
                  Our index investing will then show you the results:
                </p>
                <ul>
                  <li>
                    <strong>Invested money</strong>: The total sum of your
                    investments is displayed, which in this scenario amounts to
                    £4,660,000, considering both the initial investment and the
                    monthly contributions over the 20-year period.
                  </li>
                  <li>
                    <strong>Current value</strong>: The calculator estimates the
                    current value of your investment, which, according to the
                    growth trajectory of the Nasdaq index, would be an
                    impressive £52,060,757.78.
                  </li>
                  <li>
                    <strong>Gains</strong>: This reflects the profit generated
                    by your investment. For our hypothetical investor, the gains
                    would be £47,400,757.78 — a testament to the power of
                    consistent investing and market performance.
                  </li>
                  <li>
                    <strong>Graph</strong>: The accompanying graph provides a
                    visual journey of your investment, showing a significant
                    uptrend in value over time, mirroring the vigorous growth of
                    the Nasdaq.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              By following these steps, the calculator demonstrates how
              consistent investments in an index fund can potentially lead to
              significant growth, harnessing the momentum of the market to build
              wealth.
            </p>
            <p>
              It becomes evident how a strategic, long-term approach to
              investing in index funds can result in substantial financial
              gains, allowing your initial capital to multiply manifold through
              the virtue of the market's historical growth trends.
            </p>

            <h2>The Strategic Edge of Index Investing</h2>
            <p>
              Index investing is more than just a passive investment strategy;
              it’s a sophisticated approach with several compelling advantages.
              Here’s why many investors consider it an essential component of a
              well-crafted investment portfolio:
            </p>
            <ol>
              <li>
                <p>
                  <strong>Built-in diversification</strong>
                  <br />A single index fund can provide exposure to a broad
                  range of sectors and companies, helping to mitigate individual
                  stock volatility and reduce unsystematic risk.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cost efficiency</strong>
                  <br />
                  With lower management fees compared to actively managed funds,
                  index funds put more of your money to work in the market,
                  potentially leading to better net returns.
                </p>
              </li>
              <li>
                <p>
                  <strong>Simplicity and transparency</strong>
                  <br />
                  Index funds are straightforward to understand and offer
                  transparent investment holdings, making them an excellent
                  choice for investors who prefer a clear, uncomplicated
                  strategy.
                </p>
              </li>
              <li>
                <p>
                  <strong>Disciplined investment approach</strong>
                  <br />
                  By following the overall market or specific sectors, index
                  investing encourages a disciplined, long-term approach,
                  avoiding the pitfalls of market-timing and frequent trading.
                </p>
              </li>
              <li>
                <p>
                  <strong>Strong historical returns</strong>
                  <br />
                  Over long periods, index funds have consistently delivered
                  robust returns, often outperforming actively managed funds
                  after fees and taxes are taken into account.
                </p>
              </li>
              <li>
                <p>
                  <strong>Accessibility for everyone</strong>
                  <br />
                  Whether you're just starting out or are a seasoned investor,
                  index funds are an accessible way to gain market exposure,
                  requiring no extensive research or stock-picking expertise.
                </p>
              </li>
            </ol>
            <p>
              Index investing stands out as a strategic ally for those looking
              to grow their wealth reliably over time. It aligns with the
              fundamental investment wisdom of patience, diversification, and
              cost consciousness, making it a cornerstone of modern investment
              philosophy.
            </p>

            <h2>Conclusion and next steps</h2>
            <p>
              Embracing index investing can be a powerful move towards achieving
              your financial objectives. It offers a balanced blend of
              diversification, cost-effectiveness, and ease of management,
              making it a standout strategy in the investment landscape. As you
              have seen through our Index Investing Calculator, even regular
              contributions to an index fund can compound into substantial
              wealth over time.
            </p>
            <p>
              As you continue to navigate your investment journey, consider
              incorporating index funds as part of a broader passive investing
              strategy. This approach not only aligns with the ethos of index
              investing but also complements it by offering a streamlined
              pathway to building your portfolio.
            </p>
            <p>
              For those eager to delve deeper into the tenets of smart,
              long-term investment strategies, check out my blog on: What is
              passive investing?
            </p>
          </article>
        </div>
      </div>
    </article>
  );
};

export default IndexInvestingCalculator;

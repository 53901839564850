const Header = () => {
  return (
    <>
      <header>
        <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
          Tools
        </span>
        <h1 className="text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 mt-2">
          Mortgage Calculator
        </h1>
      </header>
      <div className="text-xl text-slate-700 leading-7 mt-8">
        <p>
          Dreaming of your own space? Whether it's a cozy cottage or a sprawling
          estate, the path to your dream home starts right here. With our
          streamlined mortgage calculator, demystify the numbers and confidently
          plan your future. It’s the first step in your journey home—simple,
          clear, and tailored to your aspirations. Let’s make sense of the cents
          so you can focus on what truly matters—turning a house into your home.
        </p>
      </div>
    </>
  );
};

export default Header;

import { useState } from 'react';
import uuid from 'react-uuid';
import questions from './Questions.json';

const FinancialLiteracyTestQuestions = () => {
  const [userSelection, setUserSelection] = useState(
    Array(questions.length).fill(-1)
  );

  const addClassColour = (questionIndex, optionIndex) => {
    // user haven't answered yet
    if (userSelection[questionIndex] === -1) return '';

    if (optionIndex === questions[questionIndex].correctAnswerIndex) {
      return 'bg-green-600 text-white border-none disabled:hover:bg-green-600';
    } else if (userSelection[questionIndex] === optionIndex) {
      return 'bg-red-600 text-white border-none disabled:hover:bg-red-600';
    } else {
      return 'disabled:hover:bg-white';
    }
  };

  return (
    <section>
      <h2>Let&lsquo;s test your financial literacy!</h2>
      <p>
        Now, here comes the most crucial question — how financially literate are
        you? Are you good enough? Well, take the test below, and you will know
        right away.
      </p>
      <p>Without further ado, let&lsquo;s begin the test!</p>
      <article>
        {questions.map((question, questionIndex) => (
          <section key={questionIndex + uuid()}>
            <h3
              className={`text-2xl mb-3 leading-6 text-gray-900 font-semibold ${
                questionIndex === 0 ? 'mt-7' : 'mt-8'
              }`}
            >
              Question {questionIndex + 1} of {questions.length}
            </h3>
            <p className="mb-5">{question.title}</p>
            <div className="grid grid-cols-2 gap-4">
              {question.options.map((option, optionIndex) => (
                <button
                  key={optionIndex + uuid()}
                  className={`border-[1px] border-solid border-slate-700 rounded-xl p-4 hover:bg-gray-100 ${addClassColour(
                    questionIndex,
                    optionIndex
                  )} `}
                  disabled={userSelection[questionIndex] !== -1}
                  onClick={() => {
                    setUserSelection(
                      userSelection.map((selection, i) => {
                        return questionIndex === i ? optionIndex : selection;
                      })
                    );
                  }}
                >
                  {option}
                </button>
              ))}
            </div>

            {userSelection[questionIndex] !== -1 && (
              <p className="bg-sky-100 rounded-lg mt-6 p-5">
                Explanation:{' '}
                {questionIndex === 4 ? (
                  <>
                    Same as the last question, this is a compound interest
                    question. The amount of money you will have in the bank
                    account after 5 years is equivalent to $100 × (1.10)
                    <sup>5</sup> = $161.05, which is more than $150. The first
                    year interests received will be $100 × 10% = $10. As the
                    interest is added to the opening balance of your bank
                    account next year, the interest thereafter will be larger
                    than $10. Hence, the amount of money you have in your bank
                    account will be more than $150. The compound interest
                    concept and calculation are explained in detail in our
                    compound interest calculator. Make sure you check it out!
                  </>
                ) : (
                  question.description
                )}
              </p>
            )}
          </section>
        ))}
      </article>
    </section>
  );
};

export default FinancialLiteracyTestQuestions;

import LineChart from '@cloudscape-design/components/line-chart';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';

const AnnualBalancesChart = ({ data }) => {
  const balances = [];
  const interestPaid = [];
  const principalPaid = [];

  for (const datum of data) {
    balances.push({ x: new Date(datum.paymentTime), y: datum.balance });
    interestPaid.push({
      x: new Date(datum.paymentTime),
      y: datum.interestPaid,
    });
    principalPaid.push({
      x: new Date(datum.paymentTime),
      y: datum.principalPaid,
    });
  }

  let chartData;
  if (
    balances.length === 0 &&
    interestPaid.length === 0 &&
    principalPaid.length === 0
  ) {
    chartData = [];
  } else {
    chartData = [
      {
        title: 'Remaining Balances',
        type: 'line',
        data: balances,
        valueFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? '$' + (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? '$' + (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? '$' + (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      },
      {
        title: 'Paid Interest',
        type: 'line',
        data: interestPaid,
        valueFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? '$' + (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? '$' + (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? '$' + (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      },
      {
        title: 'Paid Principal',
        type: 'line',
        data: principalPaid,
        valueFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? '$' + (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? '$' + (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? '$' + (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      },
    ];
  }

  return (
    <LineChart
      series={chartData}
      xDomain={[new Date(1698962384705), new Date(2327432384705)]}
      yDomain={[0, 500000]}
      i18nStrings={{
        xTickFormatter: e =>
          e
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
            })
            .split(',')
            .join('\n'),
        yTickFormatter: function o(e) {
          return Math.abs(e) >= 1e9
            ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
            : Math.abs(e) >= 1e6
            ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
            : Math.abs(e) >= 1e3
            ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
            : e.toFixed(2);
        },
      }}
      ariaLabel="Single data series line chart"
      height={300}
      hideFilter
      xScaleType="time"
      xTitle="Time"
      yTitle="$"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default AnnualBalancesChart;

import * as d3 from 'd3';

const addToolTip = (
  graphContainer,
  graph,
  selector,
  midpoint,
  mouseoverEvent,
  toolTipStyle = {}
) => {
  const defaultToolTipWidth = 230;
  const tooltip = graphContainer
    .append('div')
    .classed('tooltip', true)
    .style('position', 'absolute')
    .style('display', 'none')
    .style('opacity', 0)
    .style('width', `${toolTipStyle.width || defaultToolTipWidth}px`)
    .style('font-size', `${toolTipStyle.fontSize || 16}px`)
    .style('background', toolTipStyle.background || 'lightsteelblue')
    .style('padding', `${toolTipStyle.padding || 10}px`)
    .style('border-radius', `${toolTipStyle.borderRadius || 8}px`);

  graph
    .selectAll(selector)
    .classed('tooltip-right', (_, i) => i < midpoint)
    .classed('tooltip-left', (_, i) => i >= midpoint)
    .on('mouseout', function () {
      d3.select(this).attr('fill', '#186FAF');
      tooltip.style('opacity', 0).style('display', 'none');
    });

  graph.selectAll('.tooltip-left').on('mouseover', function (e, d) {
    d3.select(this).attr('fill', '#0F609B');
    tooltip
      .style('display', 'block')
      .style('opacity', 0.9)
      .style('top', e.pageY - 40 + 'px')
      .style(
        'left',
        e.pageX - (toolTipStyle.width || defaultToolTipWidth) - 10 + 'px'
      );
    mouseoverEvent(tooltip, d);
  });

  graph.selectAll('.tooltip-right').on('mouseover', function (e, d) {
    d3.select(this).attr('fill', '#0F609B');
    tooltip
      .style('display', 'block')
      .style('opacity', 0.9)
      .style('top', e.pageY - 40 + 'px')
      .style('left', e.pageX + 10 + 'px');
    mouseoverEvent(tooltip, d);
  });

  return tooltip;
};

export { addToolTip };

const PrivacyPolicy = () => {
  return (
    <article className="prose prose-slate prose-xl break-words mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-8">
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        Welcome to Alpha Investing Group. This privacy policy outlines how we
        collect, use, and protect your personal information. By using our
        website, you agree to the terms of this policy.
      </p>

      <h2>Information Collection</h2>
      <p>
        We collect personal information such as your name, email address, and
        location when you sign up for our newsletter or submit a comment on our
        blog. We may also collect information about your device and browsing
        behavior through cookies and other tracking technologies.
      </p>

      <h3>Google Analytics</h3>
      <p>
        We use Google Analytics to help us analyze the use of our website.
        Google Analytics uses cookies or other tracking technologies to collect
        information such as your IP address, browser type, and operating system.
        This information is used to analyze website usage, improve our content
        and services, and provide you with personalized recommendations. Google
        Analytics may also collect demographic and interest data, such as age,
        gender, and interests.
      </p>
      <p>
        We have enabled Google Analytics' Advertising Features, including
        remarketing, Google Display Network Impression Reporting, and Google
        Analytics Demographics and Interest Reporting. You can opt-out of Google
        Analytics by installing the Google Analytics Opt-out Browser Add-on,
        available at{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>
      <p>
        Please note that Amazon and Google may collect and use your personal
        information in accordance with their own privacy policies. We encourage
        you to review their privacy policies before using our website.
      </p>

      <h3>Google AdSense</h3>
      <p>
        We use Google AdSense to display advertisements on our website. Google
        AdSense uses cookies or other tracking technologies to collect
        information about your browsing activity and interests to provide you
        with personalized advertisements.
      </p>
      <p>
        We have enabled Google AdSense's Interest-Based Advertising, which means
        that Google AdSense may show you ads based on your previous browsing
        activity on our website and other websites. You can opt-out of Google
        AdSense's Interest-Based Advertising by visiting Google's Ads Settings
        at{' '}
        <a
          href="https://www.google.com/settings/ads"
          target="_blank"
          rel="noreferrer"
        >
          https://www.google.com/settings/ads
        </a>
        .
      </p>
      <p>
        Please note that Google may collect and use your personal information in
        accordance with their own privacy policies. We encourage you to review
        their privacy policies before using our website.
      </p>

      <h3>Embedded Content</h3>
      <p>
        Our website may include embedded content from other websites or
        platforms, such as videos, images, or social media feeds. This embedded
        content may collect data about your browsing activity on our website and
        other websites, and it may use cookies or other tracking technologies.
      </p>
      <p>
        We do not control the collection of data by embedded content providers,
        and we are not responsible for the privacy practices or policies of
        these providers. We encourage you to review the privacy policies of any
        embedded content providers to understand how your personal information
        may be collected, used, and disclosed.
      </p>
      <p>
        Please note that embedded content from some providers may be subject to
        additional terms and conditions, such as the provider's terms of service
        or content licensing agreements. By using our website, you agree to
        comply with any such terms and conditions.
      </p>

      <h2>Use of Information</h2>
      <p>
        We use the information we collect to personalize your experience,
        improve our content and services, and comply with legal requirements. We
        may also use your email address to send you newsletters or marketing
        communications.
      </p>

      <h2>Sharing of Information</h2>
      <p>
        We do not sell or rent your personal information to third parties.
        However, we may share it with our service providers or business partners
        who help us with website hosting, analytics, or marketing. We may also
        share your information if required by law or in response to legal
        requests.
      </p>

      <h2>User Choices and Rights</h2>
      <p>
        You have the right to access, update, or delete your personal
        information at any time. You can also opt out of receiving newsletters
        or marketing communications by following the instructions in the email.
        Please note that some data processing may be necessary for us to provide
        our services or comply with legal requirements.
      </p>
      <p>
        If you reside in a country in the European Economic Area (EEA), you have
        the following rights according to the General Data Protection Regulation
        (GDPR):
      </p>
      <ol>
        <li>To have any personal data deleted.</li>
        <li>To access your personal data.</li>
        <li>To ensure that your personal data is accurate.</li>
        <li>To restrict any additional processing of your personal data.</li>
        <li>
          You have the right to complain to a supervisory authority in your
          country if your data is misused.
        </li>
      </ol>
      <p>
        If you think that we infringe any data protection laws, then you have
        the legal right to complain to a supervisory authority.
      </p>

      <h2>Data Retention and Deletion</h2>
      <p>
        We will retain your personal information only for as long as necessary
        to fulfill the purposes we collected it for, including for the purposes
        of satisfying any legal, accounting, or reporting requirements.
      </p>
      <p>
        If you reside in the EEA, according to the General Data Protection
        Regulation (GDPR), we will retain your personal information for no
        longer than is necessary for the purposes for which it was processed.
        Upon your request, we will delete your personal information unless we
        are required by law to retain it.
      </p>
      <p>
        We will take reasonable steps to ensure the complete erasure of your
        personal information from our systems. However, please note that
        residual copies may exist in our backup systems or archives after
        deletion, and we cannot guarantee their complete removal. We will also
        keep any non-personal data or aggregated information for our internal
        analytics purposes.
      </p>

      <h2>Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, no security system is
        perfect, and we cannot guarantee the security of your information.
      </p>

      <h2>Policy Changes</h2>
      <p>
        We may update this privacy policy from time to time. We will notify you
        of any material changes by posting the updated policy on our website or
        by email.
      </p>

      <h2>Contact Us</h2>
      <p>
        Should you have any questions about our privacy policy, feel free to
        send an email to{' '}
        <a href="mailto:alphainvestinggroup.general@gmail.com">
          alphainvestinggroup.general@gmail.com
        </a>
        .
      </p>
      <p>You can also contact us by mail via this mailing address:</p>
      <p>
        Alpha Investing Group
        <br />
        Flat 607, Hennessey Apartments,
        <br />
        5 Brigadier Walk,
        <br />
        SE18 6YT London,
        <br />
        United Kingdom.
      </p>
    </article>
  );
};

export default PrivacyPolicy;

import LineChart from '@cloudscape-design/components/line-chart';
import Typography from '@mui/material/Typography';

const CHART_DATA = [
  {
    title: 'US property value annual growth',
    type: 'line',
    data: [
      { x: new Date(2011, 0), y: -1.83 },
      { x: new Date(2012, 0), y: 9.07 },
      { x: new Date(2013, 0), y: 9.27 },
      { x: new Date(2014, 0), y: 8.3 },
      { x: new Date(2015, 0), y: 4.28 },
      { x: new Date(2016, 0), y: 0.08 },
      { x: new Date(2017, 0), y: 6.65 },
      { x: new Date(2018, 0), y: 0.03 },
      { x: new Date(2019, 0), y: -0.29 },
      { x: new Date(2020, 0), y: 2.08 },
      { x: new Date(2021, 0), y: 18.45 },
      { x: new Date(2022, 0), y: 16.33 },
    ],
    valueFormatter: e => `${e}%`,
  },
  {
    title: 'S&P 500 annual growth',
    type: 'line',
    data: [
      { x: new Date(2011, 0), y: 2.04 },
      { x: new Date(2012, 0), y: 14.15 },
      { x: new Date(2013, 0), y: 18.99 },
      { x: new Date(2014, 0), y: 11.92 },
      { x: new Date(2015, 0), y: -2.74 },
      { x: new Date(2016, 0), y: 17.45 },
      { x: new Date(2017, 0), y: 23.91 },
      { x: new Date(2018, 0), y: -4.24 },
      { x: new Date(2019, 0), y: 19.28 },
      { x: new Date(2020, 0), y: 15.15 },
      { x: new Date(2021, 0), y: 21.57 },
      { x: new Date(2022, 0), y: -9.72 },
    ],
    valueFormatter: e => `${e}%`,
  },
];

const PropertyValueVersusOtherInvestmentGraph = () => {
  return (
    <>
      <LineChart
        series={CHART_DATA}
        xDomain={[new Date(2011, 0), new Date(2022, 1)]}
        yDomain={[-10, 25]}
        i18nStrings={{
          xTickFormatter: e =>
            e.toLocaleDateString('en-US', {
              year: 'numeric',
            }),
          yTickFormatter: function o(e) {
            return `${e}%`;
          },
        }}
        height={300}
        hideFilter
        xScaleType="time"
        xTitle="Year"
        yTitle="Percentage"
      />
      <figcaption>
        <Typography
          color="black"
          fontSize="14px"
          textAlign="center"
          mt={2}
          fontWeight="bold"
        >
          US property value annual growth versus S&P 500 annual growth
        </Typography>
      </figcaption>
    </>
  );
};

export default PropertyValueVersusOtherInvestmentGraph;

import * as d3 from 'd3';
import regression from 'regression';
import { investmentReturn, date } from './data';
import { addAxis } from '../utils/axes';

const graph3 = () => {
  const aspectRatio = 1.5;
  const width = 739;
  const height = width / aspectRatio;
  const margin = { top: 10, right: 0, bottom: 38, left: 50 };

  const parsedDate = date.map(d => d3.timeParse('%d-%m-%Y')(d));
  const xScale = d3
    .scaleTime()
    .domain([d3.timeParse('%d-%m-%Y')('01-01-2000'), d3.max(parsedDate)])
    .range([margin.left, width - margin.right]);

  const yMaxValue = 350;
  const yScale = d3
    .scaleLinear()
    .domain([0, yMaxValue])
    .range([height - margin.bottom, margin.top]);

  const data = investmentReturn.map((d, i) => {
    return {
      investmentReturn: d,
      date: parsedDate[i],
    };
  });

  const regressionData = investmentReturn.map((d, i) => {
    const myDate = date[i].split('-');
    [myDate[0], myDate[1]] = [myDate[1], myDate[0]];
    return [Date.parse(myDate.join('/')) / 10000000000, d];
  });
  const bestFitFunction = regression.exponential(regressionData);
  const bestFitData = regressionData.map((d, i) => {
    return {
      investmentReturn: bestFitFunction.predict(d[0])[1],
      date: parsedDate[i],
    };
  });

  const graphContainer = d3
    .select('#graph3')
    .text('')
    .style('overflow', 'auto');
  const graph = graphContainer
    .append('svg')
    .attr('height', height)
    .attr('width', width);
  graphContainer
    .append('figcaption')
    .classed('text-center text-sm', true)
    .html(
      'Figure 3: S&P 500 investment returns from January 2000 to September 2022, source: <a href="https://www.investing.com" target="_blank" rel="noreferrer noopener">investing.com</a>'
    );

  // line chart
  graph
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'steelblue')
    .attr('stroke-width', 1.5)
    .attr(
      'd',
      d3
        .line()
        .x(d => xScale(d.date))
        .y(d => yScale(d.investmentReturn))
    );

  // best fit line chart
  graph
    .append('path')
    .datum(bestFitData)
    .attr('fill', 'none')
    .attr('stroke-dasharray', '5, 5')
    .attr('stroke', 'rgb(234 88 12)')
    .attr('stroke-width', 1.5)
    .attr(
      'd',
      d3
        .line()
        .x(d => xScale(d.date))
        .y(d => yScale(d.investmentReturn))
    );

  addAxis('left', graph, yScale, 'Investment Return (%)', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 50,
    },
    label: {
      textLength: 130,
      translateY: -39,
    },
  });

  addAxis('bottom', graph, xScale, 'Date', {
    graph: {
      height: height,
      width: width,
      margin: margin,
    },
    ticks: {
      dataGap: 365.24 * 24 * 60 * 60 * 1000 * 2,
      tickSize: 6,
      translateY: 15,
    },
    label: {
      defaultFormat: true,
      textLength: 30,
      translateY: 36,
    },
  });
};

export default graph3;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/alpha-investing-logo.svg';

const NavBar = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [initMobVis, setInitMobVis] = useState('invisible');

  // quickfix for animation during inital render
  const activateMobileMenu = () => {
    setInitMobVis('');
    setMobileMenuActive(true);
  };

  return (
    <div className="relative bg-white border-b-2 border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-6">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/" className="flex items-center">
              <img
                className="h-8 w-auto mr-2"
                src={logo}
                alt="Alpha Investing Group Logo"
              />
              <span className="text-2xl font-bold">Alpha Investing Group</span>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={activateMobileMenu}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open menu</span>
              {/* Heroicon name: outline/menu */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <Link
              to="/blogs"
              className="text-lg font-medium text-gray-500 hover:text-gray-900"
            >
              Blogs
            </Link>
            <Link
              to="/tools"
              className="text-lg font-medium text-gray-500 hover:text-gray-900"
            >
              Tools
            </Link>
          </nav>
        </div>
      </div>

      {/*Mobile menu*/}
      <div
        className={`absolute z-10 top-0 inset-x-0 p-2 md:hidden origin-top-right ${initMobVis} ${
          mobileMenuActive ? 'fade-in' : 'fade-out'
        }`}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="Alpha Investing Group Logo"
                />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={() => setMobileMenuActive(false)}
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                <Link
                  to="/blogs"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  {/* Heroicon name: outline/pencil-alt */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-shrink-0 h-6 w-6 text-blue-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    {' '}
                    Blogs{' '}
                  </span>
                </Link>

                <Link
                  to="/tools"
                  className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  {/* Heroicon name: outline/calculator */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-shrink-0 h-6 w-6 text-blue-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3 text-base font-medium text-gray-900">
                    {' '}
                    Tools{' '}
                  </span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

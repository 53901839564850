import {
  TOP_10_COINS_ENDPOINT,
  QUERY_COINS_ENDPOINT,
  COIN_PRICE_ENDPOINT,
} from './constants';

export async function requestTop10Coins() {
  try {
    const res = await fetch(TOP_10_COINS_ENDPOINT);
    if (res.ok) {
      const json = await res.json();
      return json.map(({ id, name, image }) => {
        return {
          id,
          name,
          image,
        };
      });
    } else {
      throw new Error(
        'There was an error fetching the data. Please try again later.'
      );
    }
  } catch (err) {
    throw new Error(
      'There was an error fetching the data. Please try again later.'
    );
  }
}

export async function requestCoinValue(coinID) {
  try {
    const res = await fetch(`${COIN_PRICE_ENDPOINT}/${coinID}`);
    if (res.ok) {
      const json = await res.json();
      return json.market_data.current_price.usd;
    } else {
      throw new Error(
        'There was an error fetching the data. Please try again later.'
      );
    }
  } catch (err) {
    throw new Error(
      'There was an error fetching the data. Please try again later.'
    );
  }
}

export async function queryCoins(query) {
  try {
    const res = await fetch(`${QUERY_COINS_ENDPOINT}?query=${query}`);
    if (res.ok) {
      const json = await res.json();
      return json.coins.slice(0, 10).map(({ id, name, large }) => {
        return {
          id,
          name,
          image: large,
        };
      });
    } else {
      throw new Error(
        'There was an error fetching the data. Please try again later.'
      );
    }
  } catch (err) {
    throw new Error(
      'There was an error fetching the data. Please try again later.'
    );
  }
}

import { DatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { NumericFormat } from 'react-number-format';
import Typography from '@mui/material/Typography';
import { useFormContext, Controller } from 'react-hook-form';

import { INDEX_TYPES } from './helpers';

const InvestmentDetailsForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          minWidth: '534px',
          marginTop: '16px',
          marginBottom: '16px',
          padding: '48px',
          borderRadius: '16px',
          boxShadow:
            '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          component="p"
          fontWeight={700}
          fontSize="20px"
          fontFamily="inherit"
          mb={2}
        >
          Initial investment
        </Typography>

        <Controller
          name="investAmount"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field: { onChange, value, ref } }) => (
            <NumericFormat
              getInputRef={ref}
              value={value}
              onValueChange={v => onChange(v.floatValue)}
              customInput={TextField}
              error={!!errors.investAmount}
              helperText={
                errors.investAmount ? errors.investAmount.message : ''
              }
              prefix="$"
              decimalScale="2"
              thousandSeparator
              hiddenLabel
              variant="outlined"
              fullWidth
              size="normal"
              placeholder="$10,000"
            />
          )}
        />
        <Box mt={2}>
          <Typography
            component="p"
            fontWeight={700}
            fontSize="20px"
            fontFamily="inherit"
            mb={2}
          >
            Monthly investment
          </Typography>
          <Controller
            name="monthlyInvestment"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { onChange, value, ref } }) => (
              <NumericFormat
                getInputRef={ref}
                value={value}
                onValueChange={v => onChange(v.floatValue)}
                customInput={TextField}
                error={!!errors.monthlyInvestment}
                helperText={
                  errors.monthlyInvestment
                    ? errors.monthlyInvestment.message
                    : ''
                }
                prefix="$"
                decimalScale="2"
                thousandSeparator
                hiddenLabel
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="$10,000"
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Typography
            component="p"
            fontWeight={700}
            fontSize="20px"
            fontFamily="inherit"
            mb={2}
          >
            Start date
          </Typography>
          <Controller
            name="startDate"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <DatePicker
                {...field}
                sx={{ width: '100%' }}
                views={['year', 'month']}
                format="MM/yyyy"
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Typography
            component="p"
            fontWeight={700}
            fontSize="20px"
            fontFamily="inherit"
            mb={2}
          >
            End date
          </Typography>
          <Controller
            name="endDate"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <DatePicker
                {...field}
                sx={{ width: '100%' }}
                views={['year', 'month']}
                format="MM/yyyy"
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Typography
            component="p"
            fontWeight={700}
            fontSize="20px"
            fontFamily="inherit"
            mb={2}
          >
            Index
          </Typography>
          <Controller
            name="index"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { value, ...fields } }) => (
              <TextField
                {...fields}
                value={value ?? ''}
                error={!!errors.index}
                helperText={errors.index ? errors.index.message : ''}
                hiddenLabel
                select
                variant="outlined"
                fullWidth
                size="normal"
              >
                {INDEX_TYPES.map(index => (
                  <MenuItem key={index} value={index}>
                    {index === 'spx' ? 'S&P 500' : 'Nasdaq'}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
        <Box mt={3}>
          <Button
            type="submit"
            mt={5}
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            size="large"
          >
            Get my results
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default InvestmentDetailsForm;

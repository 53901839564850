const Avatar = ({ author }) => {
  const initials = author
    .split(' ')
    .map(name => name[0])
    .join('');

  const shortenedInitials = `${initials[0]}${initials[initials.length - 1]}`;

  return (
    <>
      <span className="sr-only">{author}</span>
      <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
        <span className="text-lg font-medium">{shortenedInitials}</span>
      </div>
    </>
  );
};

export default Avatar;
